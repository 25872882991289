import styled from 'styled-components';
import { themeMediaQuery, themeSpace } from '@utils/styledTheme.util';
import { Columns } from '@components/Columns';
import { Box } from '@components/Box';
import { Text } from '@components/typography';
import { Flex } from '@components/Flex';
import { SpacingStack } from '@components/SpacingStack';
import { TextPairing } from '@components/TextPairing';

export const Phone = styled.div`
  border: 8px solid #ffffff;
  box-shadow: 0px 24px 48px #ffd7d4;
  border-radius: 64px;
  width: 416px;
  height: 870px;
`;

export const PhotoCollectionBottomAlignedColumns = styled(Columns)`
  align-item: center;
`;

export const FlexIconWrapper = styled(Flex)`
  &:not(:first-child) {
    margin-left: ${themeSpace(11)};
  }
  ${themeMediaQuery('below_sm')} {
    width: 80% !important;
    flex-direction: row;
    margin-bottom: ${themeSpace(9)} !important;
    margin-top: ${themeSpace(9)} !important;
    img {
      margin-right: ${themeSpace(5)};
    }
    &:not(:first-child) {
      margin-left: ${themeSpace(0)};
    }
  }
`;

export const PhoneFrameWrapper = styled(Box)`
  position: absolute;
  left: 48%;
  transform: translateX(-50%);
  top: 12%;
  ${themeMediaQuery('below_sm')} {
    display: none;
  }
`;

export const ParagraphTextWrapper = styled(Text)`
  max-width: 297px;
  margin-top: ${themeSpace(10)};
  ${themeMediaQuery('below_sm')} {
    text-align: center;
  }
`;

export const PhotoCollectionIconsWrapper = styled(SpacingStack)`
  ${themeMediaQuery('below_sm')} {
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-bottom: ${themeSpace(6)};
  }
  ${themeMediaQuery('md')} {
    display: flex;
    width: 100%;
    padding-left: 10%;
    padding-right: 10%;
    justify-content: space-between;
    margin-bottom: ${themeSpace(12)};
  }
`;

export const PhotoServiceDetail = styled(Text)`
  max-width: 217px;
  font-size: 16px;
  line-height: 21px;
`;

export const PhotoDetail = styled(Columns.Column)`
  display: flex;
  flex-direction: row;
  justify-content: center;
`;

export const PhotoDetailBox = styled(Box)`
  ${themeMediaQuery('below_sm')} {
    width: 100%;
    text-align: center;
  }
`;

export const MainTextPairing = styled(TextPairing)`
  .text-pairing-title {
    max-width: 330px;
    ${themeMediaQuery('xl')} {
      max-width: 410px;
    }
  }
`;
