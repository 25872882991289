import React, { useState } from 'react';
import { Text } from '@components/typography';
import { JoykitColor } from '@components/themes';
import {
  TestimonialContainer,
  TestimonialDetails,
  TestimonialAsset,
  TitleWrapper,
  StyledQuotesContainer,
  DateWrapper,
  DetailWrapper,
  TestimonialContent,
} from './TestimonialSection.styles';
import { LazyLoadWrapper } from '@components/LazyLoadWrapper';

type quotesType = {
  imagePath: string;
  title: string;
  author: string;
  dateMarried: string;
  photoAttribution: string;
};

type VerticalCarouselProps = {
  // Whether to display image carousel on the left side or the right side.
  carouselVariant: 'left' | 'right';
  backgroundColor: JoykitColor;
  quotes: quotesType[];
  mobileQuoteIndex?: number;
};

export const TestimonialSection: React.FC<VerticalCarouselProps> = props => {
  const { quotes, mobileQuoteIndex = 0, backgroundColor = 'blue6', carouselVariant = 'right' } = props;

  const [isVisible, setIsVisible] = useState(false);
  return (
    <TestimonialContainer carouselVariant={carouselVariant} backgroundColor={backgroundColor}>
      <LazyLoadWrapper beforeLoad={() => setIsVisible(true)}>
        <div />
      </LazyLoadWrapper>
      <DetailWrapper>
        <TestimonialDetails>
          <TestimonialContent>
            <TitleWrapper>
              <Text tagName="h2" variant="hed72Hero" color="blue3">
                {`“${quotes[mobileQuoteIndex].title}”`}
              </Text>
            </TitleWrapper>
            <StyledQuotesContainer>
              <Text textAlign="left" tagName="h3" variant="dek20" color="blue3">
                {quotes[mobileQuoteIndex].author}
              </Text>
              <DateWrapper textAlign="left" tagName="h3" variant="featureCopy17" color="blue3">
                {quotes[mobileQuoteIndex].dateMarried}
              </DateWrapper>
              <Text textAlign="left" tagName="p" variant="caption" color="blue3">
                {quotes[mobileQuoteIndex].photoAttribution}
              </Text>
            </StyledQuotesContainer>
          </TestimonialContent>
        </TestimonialDetails>
      </DetailWrapper>
      {isVisible ? <TestimonialAsset src={quotes[mobileQuoteIndex].imagePath} /> : null}
    </TestimonialContainer>
  );
};

export default TestimonialSection;
