import styled from 'styled-components';
import { Box } from '@components/Box';
import { CloudImage, PlaceholderCloudImage } from '@components/CloudImage';
import { themeMediaQuery } from '@utils/styledTheme.util';
import { TextPairing } from '@components/TextPairing';
import { cloudAssetUrl } from '@utils/cloudAssetUrl.util';

export const SectionContainer = styled(Box)<{ pageVariant: string }>`
  height: 100%;
  position: relative;
  display: flex;
  align-items: flex-end;

  ${themeMediaQuery('below_sm')} {
    overflow: hidden;
  }
  background: ${({ pageVariant }) => (pageVariant === 'registry' ? '#cdecfe' : 'transparent')};
`;

export const LottieContainer = styled.div<{ backgroundImage?: string }>`
  width: 100%;
  background: ${({ backgroundImage }) => (backgroundImage ? `transparent url(${cloudAssetUrl(backgroundImage)})` : 'transparent')};
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
`;

export const SectionCloudImage = styled(CloudImage)`
  position: relative;
  width: 100%;
  height: 100%;
  bottom: 0px;
  max-height: 91vw;
  object-fit: cover;
  object-position: bottom;
  ${themeMediaQuery('below_sm')} {
    max-height: 100vh;
    width: 300vw;
    margin-left: -100vw;
    margin-right: -100vw;
  }
`;

export const SectionPlaceholderCloudImage = styled(PlaceholderCloudImage)`
  position: relative;
  width: 100%;
  height: 100%;
  bottom: 0px;
  max-height: 91vw;
  object-fit: cover;
  object-position: bottom;
  ${themeMediaQuery('below_sm')} {
    max-height: 100vh;
    width: 300vw;
    margin-left: -100vw;
    margin-right: -100vw;
  }
`;

export const PairedText = styled.div`
  z-index: 1;
  position: absolute;
  left: 50%;
  top: 9%;
  transform: translateX(-50%);
  width: 100%;
`;

export const TextPairingWrapper = styled(TextPairing)`
  .text-pairing-title {
    max-width: 690px;
  }
  .text-pairing-body {
    max-width: 550px;
  }
  ${themeMediaQuery('below_sm')} {
    .text-pairing-title {
      max-width: 281px;
    }
    .text-pairing-body {
      max-width: 300px;
    }
  }
  ${themeMediaQuery('xl')} {
    .text-pairing-title {
      max-width: 810px;
    }
  }
`;

export const LottieWrapper = styled(Box)<{ pageVariant: string }>`
  position: absolute;
  width: 100%;
  bottom: ${props => (props.pageVariant === 'app' || props.pageVariant === 'registry' ? '-5px' : '11vw')};
  ${themeMediaQuery('below_sm')} {
    width: 300vw;
    margin-left: -100vw;
    margin-right: -100vw;
    bottom: ${props => (props.pageVariant === 'app' || props.pageVariant === 'registry' ? '-5px' : '30vw')};
    svg {
      min-height: 61vw;
    }
  }
`;

export const GradientWrap = styled(Box)`
  height: 100%;
  position: absolute;
  top: 0;
  z-index: 1;
  width: 100%;
  left: 0;
  background: linear-gradient(180deg, #000000 16.05%, rgba(0, 0, 0, 0) 100%);
  opacity: 0.6;
  ${themeMediaQuery('below_sm')} {
    background: rgba(0, 0, 0, 0.4);
    opacity: 1;
  }
`;

export const LazyLoadWrapperPlaceholder = styled(Box)`
  position: absolute;
  top: 0;
`;
