export interface ABCSectionData {
  title: string;
  content: string;
  link: { label: string };
  video: {
    embedUrl: string;
    title: string;
    posterUrl: string;
  };
}

export const abcSectionData: ABCSectionData = {
  title: 'Put on your planning shoes.',
  content: 'We’ll get your wedding started on the right foot.',
  link: {
    label: 'Get Started',
  },
  video: {
    title: 'Dancing Shoes Video',
    embedUrl: 'https://player.vimeo.com/video/463869972?h=a171b95e65&badge=0&player_id=0&app_id=58479',
    posterUrl: 'sitewide/block_abc/abc-static.jpg',
  },
};
