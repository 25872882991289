import { styled } from '@styledComponents';
import { Columns } from '@components/Columns';

export const StyledColumns = styled(Columns)`
  height: 100%;
`;

export const StyledColumn = styled(Columns.Column)`
  height: 100%;
`;

export const VideoOverlay = styled.span`
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  background-color: #000;
  z-index: 3;
  opacity: 0.4;
`;
