import React, { useRef, useState } from 'react';
import { SpacingStack } from '@components/SpacingStack';
import { LazyLoadWrapper } from '@components/LazyLoadWrapper';
import { PhoneWrapper, ScheduleTextWrapper, SectionWrapper, WeddingScheduleWrapper } from './WeddingScheduleSection.styles';
import { appPageData } from '@data-ts/pages/app';
import { TextPairingWrapper } from './WeddingScheduleSection.styles';
import { defaultBase64Placeholder } from '@components/CloudImage';
import { cloudAssetUrl } from '@utils/cloudAssetUrl.util';

export const WeddingScheduleSection: React.FC<{}> = () => {
  const weddingScheduleData = appPageData.weddingSchedule;
  const weddingSchedule = useRef<HTMLDivElement>(null);
  let [isScrolledIntoView, setScrolledIntoView] = useState(false);

  return (
    <SectionWrapper
      backgroundImage={isScrolledIntoView ? cloudAssetUrl('app/wedding-schedule/wedding_schedule_bg.jpg') : defaultBase64Placeholder}
      paddingTop={[10, 10, 12]}
      paddingX={[5, 5, 11]}
      id="App_Wedding_Schedule"
    >
      <LazyLoadWrapper beforeLoad={() => setScrolledIntoView(true)}>
        <div />
      </LazyLoadWrapper>
      <SpacingStack spacing={10}>
        <ScheduleTextWrapper ref={weddingSchedule} id="weddingSchedule">
          <TextPairingWrapper
            alignment="center"
            eyebrow={weddingScheduleData.eyebrow}
            eyebrowColor="red2"
            eyebrowVariant="sectionEyeBrow24"
            eyebrowTagName="h2"
            title={weddingScheduleData.title}
            titleColor="red4"
            titleVariant="hed96Break"
            titleTagName="h3"
            body={weddingScheduleData.content}
            bodyVariant="dek24"
            bodyColor="red1"
          />
        </ScheduleTextWrapper>
        <PhoneWrapper>
          <WeddingScheduleWrapper
            src="app/wedding-schedule/wedding-schedule.svg"
            alt={weddingScheduleData.altText}
            width="100%"
            isMainSrcShown={isScrolledIntoView}
          />
        </PhoneWrapper>
      </SpacingStack>
    </SectionWrapper>
  );
};
