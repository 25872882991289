import React, { useLayoutEffect, useRef, useState } from 'react';
import { Box } from '@components/Box';
import { Flex } from '@components/Flex';
import { Columns } from '@components/Columns';
import { CloudImage } from '@components/CloudImage';
import { TextPairing } from '@components/TextPairing';
import { useTheme } from '@styledComponents';
import {
  BannerWrapper,
  GiveTheTextPairing,
  HeroImageContentSchedule,
  HeroImageMobileContentSchedule,
  HeroMobileScheduleImage,
  HeroMobileSectionWrapper,
  HeroSectionTextWrapper,
  HeroSectionWrapper,
  HeroTextWrapper,
  HostGoTextPairing,
  HostGoWrapper,
  IphoneShellWrapper,
  IphoneWrapper,
  MobileHeroTextWrapper,
  MobileIphoneWrapper,
  MobileShellContainer,
  ParaGraphWrapper,
  RedIphoneShell,
  SectionImageWrapper,
  SectionMobileWrapper,
  StyledCarousel,
  YellowIphoneShell,
  HostDataColumns,
  GiveDataColumns,
  HeroOverlayBox,
  BoxContainer,
  HeroWrapper,
  HeroOverlayBox2,
  HeroOverlayBox3,
  IOSAndroidStyledFlex,
  HeroMobileSectionTextPair,
  StyledIOSAppCTALink,
  StyledAndroidAppCTALink,
} from './GiveTheBestAndHostGoSection.styles';
import { useIsMounted } from '@hooks/useIsMounted';
import { useScrollController } from '@context/ScrollControllerProvider/useScrollController';
import ScrollMagic from 'scrollmagic';
import { gsap, Linear } from 'gsap';
import { appPageData } from '@data-ts/pages/app';
import { useThemeMediaQuery } from '@hooks/useThemeMediaQuery';
import { CarouselIndicator } from '@components/Carousel';
import styled from 'styled-components';
import { themeMediaQuery } from '@utils/styledTheme.util';
import { ActiveCarouselIndicatorDot, InactiveCarouselIndicatorDot } from '@components/Carousel/CarouselIndicator/CarouselIndicator.styles';
import { AppHeroAppSection } from '@sections/App/AppHeroAppSection';
import { CTALink } from '@components/CTALink';
import { LazyLoadWrapper } from '@components/LazyLoadWrapper';

const AppHeroAndGiveTheBestSectionDesktop = () => {
  const heroImageRef = useRef<HTMLDivElement>(null);
  const [isFurtherAssetsInitialized, setFurtherAssetsInitialized] = useState(false);
  const scrollController = useScrollController();
  const isMounted = useIsMounted();
  const sectionData = appPageData.giveTheBestAndHostGoData;
  const heroData = appPageData.heroSection;
  const theme = useTheme();
  const isMdDevice = useThemeMediaQuery('md');

  useLayoutEffect(() => {
    let scene: InstanceType<typeof ScrollMagic.Scene> | undefined;
    let heroSectionTimeLine: ReturnType<typeof gsap.timeline>;
    if (scrollController && isMounted && heroImageRef) {
      const initTimeline = () => {
        const heroImageEl = heroImageRef.current;
        if (scrollController && heroImageEl) {
          heroSectionTimeLine = gsap.timeline({
            paused: true,
            ease: Linear.easeNone,
          });
          heroSectionTimeLine.call(
            () => {
              setFurtherAssetsInitialized(true);
            },
            [],
            0.01
          );
          heroSectionTimeLine.to(
            '#heroSectionImage',
            {
              opacity: 0,
              duration: 0.1,
              delay: 0.7,
            },
            0
          );

          heroSectionTimeLine.to(
            '#GiveTheBestTitle',
            {
              transform: isMdDevice ? 'translateY(-80vh)' : 'translateY(-90vh)',
            },
            '-=0.2'
          );
          heroSectionTimeLine.to(
            '#GiveTheBestText',
            {
              transform: isMdDevice ? 'translateY(-80vh)' : 'translateY(-90vh)',
            },
            '-=0.5'
          );

          heroSectionTimeLine.to('#App_Hero', {
            transform: isMdDevice ? 'translateX(50vw)' : 'translateX(43vw)',
          });
          heroSectionTimeLine.to(
            '#HostGoText',
            {
              x: '44vw',
              opacity: 1,
            },
            '-=0.5'
          );

          heroSectionTimeLine.to(
            '#GiveTheBestTitle',
            {
              transform: 'translate(55vw, -90vw)',
              opacity: 0,
            },
            '-=0.5'
          );
          heroSectionTimeLine.to(
            '#GiveTheBestText',
            {
              transform: 'translate(55vw, -80vw)',
              opacity: 0,
            },
            '-=0.5'
          );
          heroSectionTimeLine.to(
            '#IphoneYellow',
            {
              opacity: 0,
              duration: 0.1,
            },
            '-=0.2'
          );
          heroSectionTimeLine.to(
            '#IphoneRed',
            {
              opacity: 1,
              duration: 0.1,
            },
            '-=0.4'
          );
          heroSectionTimeLine.to(
            '#HostGoImage',
            {
              opacity: 1,
            },
            '-=0.4'
          );
        }
      };
      const initScene = () => {
        scene = new ScrollMagic.Scene({
          triggerElement: '#GiveTheGuestWrapperSection',
          triggerHook: 'onLeave',
          duration: '200%',
        })
          .setPin('#GiveTheGuestWrapperSection')
          .addTo(scrollController)
          // workaround from here https://github.com/janpaepke/ScrollMagic/issues/918 until plugin for gsap 3 released https://github.com/janpaepke/ScrollMagic/pull/920 or we update it locally
          .on('progress', (event: ScrollMagic.SceneProgressEvent) => {
            heroSectionTimeLine && heroSectionTimeLine.progress(event.progress);
          });
      };
      initTimeline();
      initScene();
    }
    const clearScene = () => {
      if (heroSectionTimeLine) {
        heroSectionTimeLine.progress(0);
        heroSectionTimeLine.kill();
      }
      if (scene) {
        scene.destroy(true);
      }
    };
    return () => {
      clearScene();
    };
  }, [scrollController, isMounted, heroImageRef, theme, isMdDevice]);
  const [isVisible, setIsVisible] = useState(false);

  return (
    <Box id="HeroAndGiveTheGuest">
      <LazyLoadWrapper beforeLoad={() => setIsVisible(true)} threshold={0}>
        <div />
      </LazyLoadWrapper>
      <HeroWrapper backgroundColor="blackDarkTheme" isVisible={isVisible}>
        <HeroOverlayBox />
        <HeroOverlayBox2 />
        {/* <HeroOverlayBox3 /> */}
        <HeroTextWrapper>
          <Columns centered>
            <Columns.Column width="six" tablet="full">
              <HeroSectionTextWrapper>
                <TextPairing
                  title={heroData.title}
                  titleVariant="hed72Hero"
                  titleColor="red1"
                  titleMaxWidth="476px"
                  titleTagName="h1"
                  textAlign="center"
                  body={heroData.body}
                  bodyVariant="dek24"
                  bodyColor="red1"
                  bodyMaxWidth="476px"
                  bodyTagName="h2"
                />
                <IOSAndroidStyledFlex marginTop={[8]} flexDirection={['row']}>
                  <CTALink
                    id="playstore"
                    href="https://itunes.apple.com/app/joy-your-wedding-app/id994411720?mt=8&utm_source=iOS-app-cta-button&utm_medium=website&utm_campaign=iOS-app"
                  >
                    <CloudImage
                      width="auto"
                      height="62px"
                      alt={heroData.altTextAppStore}
                      src={'common/buttons/app-store-v2.png'}
                      optimization="aggressive"
                    />
                  </CTALink>
                  <CTALink
                    id="googleplay"
                    href="https://play.google.com/store/apps/details?id=com.withjoy.joy&utm_source=android-app-cta-button&utm_medium=website&utm_campaign=android-app"
                  >
                    <CloudImage
                      width="auto"
                      height="60px"
                      alt={heroData.altTextGooglePlay}
                      src={'common/buttons/google-play-v2.png'}
                      optimization="aggressive"
                    />
                  </CTALink>
                </IOSAndroidStyledFlex>
              </HeroSectionTextWrapper>
            </Columns.Column>
          </Columns>
        </HeroTextWrapper>
      </HeroWrapper>
      <Box marginTop={['-200px']} id="GiveTheGuestWrapperSection">
        <Box backgroundColor="blackDarkTheme" paddingTop={[14]} id="GiveTheGuestSection">
          <Box>
            <Columns>
              <Columns.Column width={7} offset={6}>
                <Box paddingX={[0, 6]} id="GiveTheBestTitle">
                  <GiveTheTextPairing
                    alignment={['center', 'left']}
                    title={sectionData.giveTheBest.title}
                    titleMaxWidth="508px"
                    titleTagName="h2"
                    titleVariant="hed96Break"
                    titleColor="humana1Light"
                    body={sectionData.giveTheBest.body}
                    bodyColor="neutra1"
                    bodyVariant="dek20"
                    bodyMaxWidth="506px"
                  />
                </Box>
              </Columns.Column>
            </Columns>
            <Columns>
              <Columns.Column width={5}>
                <IphoneWrapper marginTop={[10, '-328px']} id="App_Hero">
                  <IphoneShellWrapper>
                    <YellowIphoneShell id="IphoneYellow">
                      <CloudImage
                        src="common/phone-frames/iphone-dark.svg"
                        alt={sectionData.giveTheBest.altYellowText}
                        width="100%"
                        optimization="aggressive"
                      />
                    </YellowIphoneShell>
                    <RedIphoneShell id="IphoneRed">
                      <CloudImage
                        src="common/phone-frames/iphone-dark.svg"
                        alt={sectionData.giveTheBest.altRedText}
                        width="100%"
                        optimization="aggressive"
                      />
                    </RedIphoneShell>
                  </IphoneShellWrapper>
                  <BannerWrapper id="heroSectionId">
                    <HeroSectionWrapper ref={heroImageRef}>
                      <Box id="coupleGiveTheBest">
                        <HeroImageContentSchedule
                          isMainSrcShown={isVisible}
                          src="app/all-in-one-place/mobile-screen-announcement-v2.jpg"
                          alt={heroData.scheduleAltText}
                          width="100%"
                          optimization="aggressive"
                        />
                      </Box>
                      <Box id="HostGoImage" style={{ opacity: 0 }}>
                        <HeroImageContentSchedule
                          isMainSrcShown={isVisible}
                          src="app/all-in-one-place/mobile-screen-guestlist-v4.jpg"
                          alt={heroData.hostAltText}
                          width="100%"
                          optimization="aggressive"
                        />
                      </Box>
                    </HeroSectionWrapper>
                  </BannerWrapper>
                </IphoneWrapper>
              </Columns.Column>
              <GiveDataColumns width={7} offset={1}>
                <Flex paddingTop={[11]} paddingBottom={[6]} flexWrap="wrap" id="GiveTheBestText">
                  {sectionData.giveTheBest.moreInfo.map((data, index) => (
                    <Box paddingX={[0, 6]} paddingY={[8]} key={index}>
                      <ParaGraphWrapper
                        icon={data.icon}
                        iconColor="humana2Light"
                        title={data.title}
                        titleTagName="h3"
                        titleVariant="featureHed17"
                        titleColor="mono7"
                        body={data.body}
                        bodyColor="neutra1"
                        bodyVariant="featureCopy17"
                      />
                    </Box>
                  ))}
                </Flex>
              </GiveDataColumns>
              <HostGoWrapper id="HostGoText">
                <HostDataColumns width={7}>
                  <Box paddingX={[0, 8]}>
                    <HostGoTextPairing
                      alignment={['center', 'left']}
                      title={sectionData.hostGo.title}
                      titleTagName="h2"
                      titleVariant="hed96Break"
                      titleColor="humana1Light"
                      body={sectionData.hostGo.body}
                      bodyColor="neutra1"
                      bodyVariant="dek20"
                      bodyMaxWidth="418px"
                    />
                  </Box>
                  <Flex paddingTop={[11]} paddingBottom={[6]} flexWrap="wrap" id="App_Details">
                    {sectionData.hostGo.moreInfo.map((data, index) => (
                      <Box paddingX={[0, 8]} paddingY={[8]} key={index}>
                        <ParaGraphWrapper
                          icon={data.icon}
                          iconColor="humana2Light"
                          title={data.title}
                          titleTagName="h3"
                          titleVariant="featureHed17"
                          titleColor="humana1Light"
                          body={data.body}
                          bodyColor="neutra1"
                          bodyVariant="featureCopy17"
                          bodyMaxWidth="225px"
                        />
                      </Box>
                    ))}
                  </Flex>
                </HostDataColumns>
              </HostGoWrapper>
            </Columns>
          </Box>
        </Box>
      </Box>
      <AppHeroAppSection />
    </Box>
  );
};

export const CarouselIndicatorWrap = styled.div<{ $right: string }>`
  position: absolute;
  z-index: 999;
  width: 100%;
  ul {
    display: flex;
    justify-content: center;
    div + div {
      margin-left: 16px;
    }
  }
  ${themeMediaQuery('below_sm')} {
    z-index: 10;
    position: relative;
    bottom: 40px;
    right: ${({ $right }) => $right};
  }
`;

const AppHeroAndGiveTheBestSectionMobile = () => {
  const sectionData = appPageData.giveTheBestAndHostGoData;
  const heroData = appPageData.heroSection;
  const [giveTheBestCarouselSelectedSlide, setGiveTheBestCarouselSelectedSlide] = useState(0);
  const [hostAndGoSelectedSlide, setHostAndGoSelectedSlide] = useState(0);

  const handleGiveTheBestCarouselActiveSlide = (value: number) => {
    setGiveTheBestCarouselSelectedSlide(value);
  };

  const handleHostAndGoActiveSlide = (value: number) => {
    setHostAndGoSelectedSlide(value);
  };

  const inactiveComponent = () => {
    return <InactiveCarouselIndicatorDot color={'mono7'} />;
  };

  const activeComponent = () => {
    return <ActiveCarouselIndicatorDot color={'mono7'} />;
  };

  const [isVisibleMobile, setIsVisibleMobile] = useState(false);
  return (
    <Box backgroundColor="blackDarkTheme" id="HeroAndGiveTheGuestMobile">
      <LazyLoadWrapper beforeLoad={() => setIsVisibleMobile(true)} threshold={0}>
        <div />
      </LazyLoadWrapper>
      <HeroMobileSectionWrapper backgroundImage={isVisibleMobile ? 'app/hero/hero.jpg' : ''}>
        <MobileHeroTextWrapper>
          <TextPairing
            alignment="center"
            title={heroData.title}
            titleVariant="hed72Hero"
            titleColor="red1"
            titleMaxWidth="300px"
            titleTagName="h1"
            body={heroData.body}
            bodyVariant="dek24"
            bodyColor="red1"
            bodyMaxWidth="300px"
            bodyTagName="h2"
          />
        </MobileHeroTextWrapper>
        <IOSAndroidStyledFlex marginTop={[10]} flexDirection={['column']}>
          <StyledIOSAppCTALink
            id="playstoreMobile"
            href="https://itunes.apple.com/app/joy-your-wedding-app/id994411720?mt=8&utm_source=iOS-app-cta-button&utm_medium=website&utm_campaign=iOS-app"
          >
            <CloudImage
              width="100%"
              height="100%"
              alt={heroData.altTextAppStore}
              src={'common/buttons/app-store-v2.png'}
              optimization="aggressive"
            />
          </StyledIOSAppCTALink>
          <StyledAndroidAppCTALink
            id="googleplayMobile"
            href="https://play.google.com/store/apps/details?id=com.withjoy.joy&utm_source=android-app-cta-button&utm_medium=website&utm_campaign=android-app"
          >
            <CloudImage
              width="100%"
              height="100%"
              alt={heroData.altTextGooglePlay}
              src={'common/buttons/google-play-v2.png'}
              optimization="aggressive"
            />
          </StyledAndroidAppCTALink>
        </IOSAndroidStyledFlex>
      </HeroMobileSectionWrapper>
      <SectionMobileWrapper backgroundColor="blackDarkTheme" paddingBottom={[10]} paddingTop={[8]} paddingX={[5]}>
        <SectionImageWrapper paddingY={[8]}>
          <MobileShellContainer>
            <MobileIphoneWrapper
              src="common/phone-frames/iphone-dark.svg"
              alt={sectionData.giveTheBest.altYellowText}
              optimization="aggressive"
            />
          </MobileShellContainer>
          <HeroMobileScheduleImage id="coupleGiveTheBest">
            <HeroImageMobileContentSchedule
              src="app/all-in-one-place/mobile-screen-announcement-v2.jpg"
              alt={heroData.scheduleAltText}
              optimization="aggressive"
            />
          </HeroMobileScheduleImage>
          <HeroMobileSectionTextPair backgroundColor="blackDarkTheme">
            <Columns>
              <Columns.Column width={12}>
                <TextPairing
                  alignment={'center'}
                  title={sectionData.giveTheBest.title}
                  titleTagName="h2"
                  titleVariant="calloutHed32"
                  titleColor="mono7"
                  body={sectionData.giveTheBest.body}
                  bodyColor="neutra1"
                  bodyVariant="featureCopy17"
                />
              </Columns.Column>
            </Columns>
          </HeroMobileSectionTextPair>
        </SectionImageWrapper>
        <StyledCarousel
          selectedSlide={giveTheBestCarouselSelectedSlide + 1}
          defaultControlsConfig={{
            nextButtonStyle: {
              opacity: '0',
              width: '10vw',
              height: '25vh',
              marginBottom: '400px',
            },
            prevButtonStyle: {
              opacity: '0',
              width: '10vw',
              height: '25vh',
              marginBottom: '400px',
            },
          }}
          autoplay={false}
          slideIndex={giveTheBestCarouselSelectedSlide}
          cellAlign={'center'}
          slidesToShow={1.5}
          wrapAround={false}
          easing="easeSinInOut"
          beforeSlide={(curIndex, newIndex) => {
            return setGiveTheBestCarouselSelectedSlide(newIndex);
          }}
          renderBottomCenterControls={null}
          initialSlideHeight={250}
        >
          {sectionData.giveTheBest.moreInfo.map((data, index) => (
            <Box marginX={5} key={index}>
              <ParaGraphWrapper
                icon={data.icon}
                iconColor="red4"
                title={data.title}
                titleTagName="h3"
                titleVariant="featureHed17"
                titleColor="mono7"
                body={data.body}
                bodyColor="neutra1"
                bodyVariant="featureCopy17"
              />
            </Box>
          ))}
        </StyledCarousel>
        <CarouselIndicatorWrap $right="35px">
          <CarouselIndicator
            quantity={sectionData.giveTheBest.moreInfo.length}
            dotsPerPage={sectionData.giveTheBest.moreInfo.length}
            infinite={false}
            activeIndex={giveTheBestCarouselSelectedSlide}
            onDotSelected={handleGiveTheBestCarouselActiveSlide}
            renderDot={inactiveComponent()}
            renderDotActive={activeComponent()}
          />
        </CarouselIndicatorWrap>
      </SectionMobileWrapper>
      <SectionMobileWrapper paddingBottom={[10]} paddingTop={[5]} paddingX={[5]} backgroundColor="blackDarkTheme">
        <Box paddingBottom={[10]}>
          <Columns>
            <Columns.Column width={12}>
              <TextPairing
                alignment={'center'}
                title={sectionData.hostGo.title}
                titleTagName="h2"
                titleVariant="calloutHed32"
                titleColor="mono7"
                body={sectionData.hostGo.body}
                bodyColor="neutra1"
                bodyVariant="featureCopy17"
              />
            </Columns.Column>
          </Columns>
        </Box>
        <StyledCarousel
          selectedSlide={hostAndGoSelectedSlide + 1}
          defaultControlsConfig={{
            nextButtonStyle: {
              opacity: '0',
              width: '10vw',
              height: '25vh',
              marginBottom: '400px',
            },
            prevButtonStyle: {
              opacity: '0',
              width: '10vw',
              height: '25vh',
              marginBottom: '400px',
            },
          }}
          autoplay={false}
          slideIndex={hostAndGoSelectedSlide}
          cellAlign={'center'}
          slidesToShow={1.5}
          wrapAround={false}
          easing="easeSinInOut"
          beforeSlide={(curIndex, newIndex) => {
            return setHostAndGoSelectedSlide(newIndex);
          }}
          renderBottomCenterControls={null}
        >
          {sectionData.hostGo.moreInfo.map((data, index) => (
            <Box marginX={5} key={index}>
              <ParaGraphWrapper
                icon={data.icon}
                iconColor="red4"
                title={data.title}
                titleTagName="h3"
                titleVariant="featureHed17"
                titleColor="mono7"
                body={data.body}
                bodyColor="neutra1"
                bodyVariant="featureCopy17"
              />
            </Box>
          ))}
        </StyledCarousel>
        <CarouselIndicatorWrap $right="50px">
          <CarouselIndicator
            quantity={sectionData.hostGo.moreInfo.length}
            dotsPerPage={sectionData.hostGo.moreInfo.length}
            infinite={false}
            activeIndex={hostAndGoSelectedSlide}
            onDotSelected={handleHostAndGoActiveSlide}
            renderDot={inactiveComponent()}
            renderDotActive={activeComponent()}
          />
        </CarouselIndicatorWrap>
      </SectionMobileWrapper>
      <AppHeroAppSection />
    </Box>
  );
};

export const AppHeroAndGiveTheBestSection: React.FC<{}> = props => {
  return (
    <BoxContainer>
      <AppHeroAndGiveTheBestSectionMobile />
      <AppHeroAndGiveTheBestSectionDesktop />
    </BoxContainer>
  );
};

export default AppHeroAndGiveTheBestSection;
