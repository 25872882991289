import { styled } from '@styledComponents';
import { themeSpace, themeMediaQuery } from '@utils/styledTheme.util';
import { Box } from '@components/Box';
import { CloudImage } from '@components/CloudImage';
import { TextPairing } from '@components/TextPairing';

export const StyledSection = styled(Box)`
  transform: translate(0px, -80px);
  ${themeMediaQuery('below_sm')} {
    transform: translate(0px, 0px);
  }
`;

export const StyledAppBody = styled(Box)`
  width: 100%;
  margin-top: -5px;
  position: relative;
  min-height: 530px;
  ${themeMediaQuery('below_sm')} {
    height: auto;
    margin-top: 5px;
  }
`;

export const AppTopWave = styled(CloudImage)`
  width: 100%;
  object-fit: cover;
  ${themeMediaQuery('below_sm')} {
    display: none;
  }
`;

export const RightSectionTextPairing = styled(TextPairing)`
  .text-pairing-eyebrow {
    margin-top: ${themeSpace(10)};
  }
  .text-pairing-title {
    max-width: 280px;
  }
  .text-pairing-body {
    max-width: 300px;
  }
  ${themeMediaQuery('xl')} {
    .text-pairing-title {
      max-width: 420px;
    }
    .text-pairing-body {
      max-width: 400px;
    }
  }
`;

export const LeftSectionWrapper = styled(Box)`
  position: relative;
  width: 100%;
  min-height: 530px;
  transform: translate(0px, -100px);
  ${themeMediaQuery('below_sm')} {
    transform: translate(0px, 0px);
    min-height: 370px;
  }
`;

export const TheAppShapeImage = styled(CloudImage)`
  position: absolute;
  top: 30px;
  left: -10px;
  width: 360px;
  ${themeMediaQuery('below_sm')} {
    top: 0px;
    left: 0px;
    right: 0;
    width: 100%;
    max-width: 300px;
  }
`;

export const TheMobileImage = styled(CloudImage)`
  height: 570px;
  position: absolute;
  bottom: 50px;
  left: 10px;
  min-width: 250px;
  ${themeMediaQuery('below_sm')} {
    bottom: 0px;
    height: auto;
    left: -10px;
    right: 0px;
    width: 100%;
    max-height: 450px;
    object-fit: contain;
    top: -100px;
    max-width: 300px;
  }
`;

export const TheGoldThreadImage = styled(CloudImage)`
  position: absolute;
  top: 0px;
  left: -10px;
  width: 340px;
  ${themeMediaQuery('below_sm')} {
    top: -30px;
    left: -10px;
    right: 0;
    width: 100%;
    max-width: 300px;
  }
`;
