export const testimonialsData = {
  readMore: 'Read More',
  seeLess: 'See Less',
  website: [
    {
      imagePath: 'sitewide/block_testimonials/testimonial_lucy-marvin.jpg',
      title: 'Joy offers everything and more than we wanted. We love the layout.',
      author: 'Lucy & Marvin',
      dateMarried: 'Married in 2014',
      photoAttribution: 'Photo by Mark Fierst Photography',
    },
    {
      imagePath: 'sitewide/block_testimonials/testimonial_nicholas-micah.jpg',
      title: 'Joy thinks of everything when it comes to creating and designing a wedding website.',
      author: 'Nicholas & Micah',
      dateMarried: 'Married in 2014',
      photoAttribution: 'Photo by Shay and Olive Photography',
    },
    {
      imagePath: 'sitewide/block_testimonials/testimonial_morgan-abe.jpg',
      title: 'I love the design! Joy looks good and is so intuitive for our guests!',
      author: ' Morgan & Abe',
      dateMarried: 'Married in 2014',
      photoAttribution: 'Photo by Liv Lyszyk Photography',
    },
  ],
  saveTheDate: [
    {
      imagePath: 'sitewide/block_testimonials/testimonial_lucy-marvin.jpg',
      title: 'It’s economical, convenient, and allows us to keep in touch with our guests.',
      author: 'Lucy & Marvin',
      dateMarried: 'Married in 2014',
      photoAttribution: 'Photo by Mark Fierst Photography',
    },
    {
      imagePath: 'sitewide/block_testimonials/testimonial_malika-dyron.jpg',
      title: 'It was really easy to set up! Joy took the guesswork out of what I should include.',
      author: 'Malika & Dyron',
      dateMarried: 'Married in 2014',
      photoAttribution: '',
    },
    {
      imagePath: 'sitewide/block_testimonials/testimonial_emily-collin.jpg',
      title: 'It’s so customizable! The layouts and suggestions make sure we cover all the bases.',
      author: 'Emily & Collin',
      dateMarried: 'Married in 2014',
      photoAttribution: 'Photo by Hand + Arrow Photography',
    },
  ],
  invites: [
    {
      imagePath: 'sitewide/block_testimonials/testimonial_lucy-marvin.jpg',
      title: 'The ease and organization of the invitations and RSVP process has been great.',
      author: 'Lucy & Marvin',
      dateMarried: 'Married in 2014',
      photoAttribution: 'Photo by Mark Fierst Photography',
    },
    {
      imagePath: 'sitewide/block_testimonials/testimonial_templatekayla-randy.jpg',
      title: 'Having everything come in and out of the same website is beyond perfect for us.',
      author: 'Kayla & Randy',
      dateMarried: 'Married in 2014',
      photoAttribution: 'Photo by Menning Photographic',
    },
    {
      imagePath: 'sitewide/block_testimonials/testimonial_tinamarie-johnny.jpg',
      title: 'My guests can instantly know the details, RSVP and even get to know people.',
      author: 'Tina Marie & Johnny',
      dateMarried: 'Married in 2014',
      photoAttribution: 'Photo by Melissa Ivy Photography',
    },
  ],
  guestList: [
    {
      imagePath: 'sitewide/block_testimonials/testimonial_templatekayla-randy.jpg',
      title: 'This alone saves my sanity!',
      author: 'Kayla & Randy',
      dateMarried: 'Married in 2014',
      photoAttribution: 'Photo by Menning Photographic',
    },
    {
      imagePath: 'sitewide/block_testimonials/testimonial_jordan-tyler.jpg',
      title: `I’d say definitely use the guest list and RSVP options.`,
      author: 'Jordan & Tyler',
      dateMarried: 'Married in 2014',
      photoAttribution: 'Photo by Andrew May Photography',
    },
    {
      imagePath: 'sitewide/block_testimonials/testimonial_malika-dyron.jpg',
      title: 'Have fun with it and launch early - you can start getting addresses and guest count.',
      author: 'Malika & Dyron',
      dateMarried: 'Married in 2014',
      photoAttribution: '',
    },
  ],
  registry: [
    {
      imagePath: 'registry/testimonials/molly-wyatt-wedding-registry.jpg',
      title:
        'I think my favorite feature is the ""Registry Advisor"" that suggests how many products to have in all the various price ranges! It is super helpful to have it broken down like that!',
      readMoreContent:
        ' Other than that I also really enjoy being able to add other items from external retailers with the ""Add to Joy"" button and having the cash funds! The only issue I have had so far was trying to sync an external registry with Walmart that I couldn’t get to work, but I found a way around it by just adding the few items I had individually onto the registry.' +
        'All in all, I really enjoy it just from the fact that I don’t have to juggle multiple registries and I can sync everything together on your website and just use that which makes it a lot less stressful! The way everything is set up makes it super easy and straightforward.' +
        'The customer support is also super helpful and very quick to reply for the few questions that I have had. 👍',
      author: 'Molly & Wyatt',
    },
    {
      imagePath: 'registry/testimonials/guadalupe-andy-wedding-registry.jpg',
      title:
        'We chose Joy for our destination wedding because it was super easy to use and offered all the features we needed. Planning a wedding away from home was a big task, but Joy made it a easy! ',
      readMoreContent:
        'Our favorite features were definitely the guest list and schedule options – they kept us on track and stress-free! ' +
        'We used the registry and it was fantastic. We particularly enjoyed the fund feature and how seamlessly it allowed us to import items from other registries. The easy exchange and return process also gave us peace of mind, making the whole experience smooth for us and our guests.',
      author: 'Guadalupe & Andy',
    },
    {
      imagePath: 'registry/testimonials/ross-jenna-wedding-registry.jpg',
      title:
        '"I love Joy’s registry feature for the ease of use! I love that Joy gives all of the great suggestions and also allows you to sync registries from other stores making it a one stop shop! So user friendly."',
      author: 'Jenna & Ross',
    },
    {
      imagePath: 'registry/testimonials/matt-malenca-wedding-registry.jpg',
      title:
        'We are super happy we decided to organize our registry through Joy. The user interface through the website makes it easy to search for and select items that we would like to add, and they provide helpful suggestions as well. ',
      readMoreContent:
        'When we couldn’t find something we wanted, we simply linked an external registry accessible on the same page. The eCard option is another major plus, providing convenience for all while maintaining a personal touch. We highly recommend it!',
      author: 'Matt & Malenca',
    },
    {
      imagePath: 'registry/testimonials/febbie-paul-wedding-registry.jpg',

      title:
        'Joy made it super easy to allow our guests to RSVP and manage a custom survey for reception dining options. Joy was easy to connect registry items from Amazon, Macy’s and other online and store vendors. ',
      readMoreContent:
        'The website tracks everything for you. We had one broken registry item and returned without any issues. I was frankly surprised at how quickly they responded to the return request. I love having one site that tracks everything. 5 Stars.',
      author: 'Paul & Febbie',
    },
    {
      imagePath: 'registry/testimonials/yolanda-connor-wedding-registry.jpg',

      title:
        'We chose Joy Registry for various reasons and we are so glad that we did! A contributing factor was the ability to password protect our website. ',
      readMoreContent:
        'We love that they do not have a processing fee for monetary gifts, they collaborate with top brands and they allow other registries on their site. Joy allows you to add any item that you desire while their “Add to Joy” button adds much convenience. On top of that, Joy’s customer service team is exceptional. It’s always a real person and they truly have all the answers to ease the stress that comes with wedding planning.',
      author: 'Yolanda & Connor',
    },
  ],
  mobileApp: [
    {
      imagePath: 'sitewide/block_testimonials/testimonial_malika-dyron.jpg',
      title: 'I’m looking forward to our guests uploading their pictures of our wedding!',
      author: 'Malika & Dyron',
      dateMarried: 'Married in 2014',
      photoAttribution: '',
    },
    {
      imagePath: 'sitewide/block_testimonials/testimonial_nicholas-micah.jpg',
      title: `This is great for my guests and makes it much easier for them to know what's happening.`,
      author: 'Nicholas & Micah',
      dateMarried: 'Married in 2014',
      photoAttribution: 'Photo by Shay and Olive Photography',
    },
    {
      imagePath: 'sitewide/block_testimonials/testimonial_lucy-marvin.jpg',
      title: 'We love the layout and that it displays our awesome engagement photos.',
      author: 'Lucy & Marvin',
      dateMarried: 'Married in 2014',
      photoAttribution: 'Photo by Mark Fierst Photography',
    },
  ],
};
