import React, { useLayoutEffect, useRef, useState } from 'react';

import { Box } from '@components/Box';
import { Columns } from '@components/Columns';
import {
  BackgroundImageContainer,
  Phone,
  PhoneContainer,
  TravelMapsContainer,
  MainTextPairing,
  TransportationMainBox,
} from './TransportationAndTravelSection.styles';
import { ReactComponent as TravelBackgroundElements } from '@images/travel/Travel_Background_Elements.svg';
import { ReactComponent as TravelMaps1 } from '@images/travel/Travel_MapsImage1.svg';
import { ReactComponent as TravelMaps2 } from '@images/travel/Travel_MapsImage2.svg';
import { appPageData } from '@data-ts/pages/app';
import { styled } from '@styledComponents';
import { useIsMounted } from '@hooks/useIsMounted';
import { useScrollController } from '@context/ScrollControllerProvider/useScrollController';
import { useThemeMediaQuery } from '@hooks/useThemeMediaQuery';
import ScrollMagic from 'scrollmagic';
import { gsap, Linear } from 'gsap';
import { LazyLoadWrapper } from '@components/LazyLoadWrapper';

const initialMapScale = 1.3;

const TransportationOptionsBlock = styled(Box)`
  position: absolute;
  bottom: 25px;
  left: 50%;
  width: 400px;
  margin-left: -200px;
`;

const StyledTravelBackgroundElements = styled(TravelBackgroundElements)`
  height: 300%;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 56vh;
  position: absolute;
  top: -65vh;
  right: 16vh;
`;

export const TransportationAndTravelSection: React.FC<{}> = () => {
  const sectionRef = useRef<HTMLDivElement>(null);
  const backgroundContainerRef = useRef<HTMLDivElement>(null);
  const [isScrolledIntoView, setScrolledIntoView] = useState(false);
  const scrollController = useScrollController();
  const isMounted = useIsMounted();
  const [travelBackgroundClassName, setTravelBackgroundClassName] = useState('');
  const travelData = appPageData.travel;

  useLayoutEffect(() => {
    let mapZoomScene: InstanceType<typeof ScrollMagic.Scene> | undefined;
    let pointersAndPathScene: InstanceType<typeof ScrollMagic.Scene> | undefined;
    let mapZoomTimeline: ReturnType<typeof gsap.timeline>;
    let pointersAndPathTimeline: ReturnType<typeof gsap.timeline>;

    if (scrollController && isMounted) {
      const sectionEl = sectionRef.current;
      const backgroundContainerEl = backgroundContainerRef.current;
      const fromToPathEl = backgroundContainerEl?.querySelector<HTMLElement>('.Travel_Background_Elements_svg__from_to_path_line');

      if (sectionEl && backgroundContainerEl && fromToPathEl) {
        mapZoomTimeline = gsap.timeline({ paused: true, ease: Linear.easeNone });
        mapZoomTimeline.fromTo(
          backgroundContainerEl,
          {
            scale: initialMapScale,
          },
          {
            scale: 1,
          },
          0
        );

        mapZoomScene = new ScrollMagic.Scene({
          triggerElement: sectionEl,
          triggerHook: 'onCenter',
          duration: sectionEl.offsetHeight,
        })
          .addTo(scrollController)
          // workaround from here https://github.com/janpaepke/ScrollMagic/issues/918 until plugin for gsap 3 released https://github.com/janpaepke/ScrollMagic/pull/920 or we update it locally
          .on('progress', (event: ScrollMagic.SceneProgressEvent) => {
            mapZoomTimeline.progress(event.progress);
          });

        pointersAndPathTimeline = gsap.timeline({ paused: true });
        pointersAndPathTimeline.to(
          fromToPathEl,
          {
            attr: {
              'stroke-dashoffset': 0,
            },
          },
          0
        );
        pointersAndPathScene = new ScrollMagic.Scene({
          triggerElement: sectionEl,
          triggerHook: 'onCenter',
          duration: '50%',
        })
          .addTo(scrollController)
          // workaround from here https://github.com/janpaepke/ScrollMagic/issues/918 until plugin for gsap 3 released https://github.com/janpaepke/ScrollMagic/pull/920 or we update it locally
          .on('progress', (event: ScrollMagic.SceneProgressEvent) => {
            if (!travelBackgroundClassName) {
              setTravelBackgroundClassName('animate-pointers');
            }
            pointersAndPathTimeline.progress(event.progress);
          });
      }

      return () => {
        if (mapZoomTimeline) {
          mapZoomTimeline.kill();
        }
        if (pointersAndPathTimeline) {
          pointersAndPathTimeline.kill();
        }
        if (mapZoomScene) {
          mapZoomScene.destroy(true);
        }
        if (pointersAndPathScene) {
          pointersAndPathScene.destroy(true);
        }
      };
    }
  }, [isMounted, scrollController, sectionRef, travelBackgroundClassName]);

  return (
    <TransportationMainBox ref={sectionRef} backgroundColor="yellow1" paddingTop={[10, 10, 12]} paddingX={8} id="App_Guest_Travel_Tools">
      <LazyLoadWrapper beforeLoad={() => setScrolledIntoView(true)}>
        <div />
      </LazyLoadWrapper>
      <MainTextPairing
        alignment="center"
        eyebrow={travelData.eyebrow}
        eyebrowVariant="sectionEyeBrow24"
        eyebrowColor="red5"
        eyebrowTagName="h2"
        title={travelData.title}
        titleColor="red6"
        titleVariant="hed96Break"
        titleTagName="h3"
        bodyColor="red6"
        bodyVariant="dek24"
        body={travelData.content}
      />
      <BackgroundImageContainer marginTop={[10, 12]} ref={backgroundContainerRef} isScrolledIntoView={isScrolledIntoView}>
        <TravelMapsContainer>
          <StyledTravelBackgroundElements />
        </TravelMapsContainer>
        <PhoneContainer>
          <Phone />
          <Columns centered={true} marginTop={'65px'}>
            <TransportationOptionsBlock>
              <Columns.Column width={12}>
                <TravelMaps1 />
              </Columns.Column>
              <Columns.Column width={12}>
                <TravelMaps2 style={{ marginTop: '-45px' }} />
              </Columns.Column>
            </TransportationOptionsBlock>
          </Columns>
        </PhoneContainer>
      </BackgroundImageContainer>
    </TransportationMainBox>
  );
};
