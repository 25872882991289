import styled from 'styled-components';
import { Box } from '@components/Box';
import { PlaceholderCloudImage } from '@components/CloudImage';
import { themeColor, themeMediaQuery, themeSpace } from '@utils/styledTheme.util';
import { TextPairing } from '@components/TextPairing';

export const SectionWrapper = styled(Box)<{ backgroundImage: string }>`
  position: relative;
  height: 1778px;
  background: ${props => `url(${props.backgroundImage})`};
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  margin-top: -80px;
  ${themeMediaQuery('below_sm')} {
    background: ${themeColor('red6')};
    height: 900px;
    margin-top: 0px;
  }
`;

export const PhoneWrapper = styled(Box)`
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: -4px;
  width: 950px;
  ${themeMediaQuery('md')} {
    width: 950px;
  }
  ${themeMediaQuery('sm')} {
    width: 800px;
  }
  ${themeMediaQuery('below_sm')} {
    max-width: 20.5rem;
  }
`;

export const WeddingScheduleWrapper = styled(PlaceholderCloudImage)`
  max-width: 100% ${themeMediaQuery('below_sm')} {
    max-width: ${themeSpace(5)};
  }
`;

export const ScheduleTextWrapper = styled.div`
  width: 100%;
  position: absolute;
  left: 0%;
`;

export const TextPairingWrapper = styled(TextPairing)`
  .text-pairing-title {
    max-width: 700px;
  }
  .text-pairing-body {
    max-width: 637px;
  }
  ${themeMediaQuery('below_sm')} {
    .text-pairing-title {
      max-width: 300px;
    }
    .text-pairing-body {
      max-width: 233px;
    }
  }
  ${themeMediaQuery('xl')} {
    .text-pairing-title {
      max-width: 935px;
    }
  }
`;
