import React, { useLayoutEffect, useRef } from 'react';
import { gsap, Linear } from 'gsap';
import { useIsMounted } from '@hooks/useIsMounted';
import { useScrollController } from '@context/ScrollControllerProvider/useScrollController';
import ScrollMagic from 'scrollmagic';
import { Box } from '@components/Box';
import { TextPairing } from '@components/TextPairing';
import {
  RegistryBox,
  StyledCloudImage,
  RegistryBoxPositionWrapper,
  RegistryBoxWrapper,
  PhoneWrapper,
  PhoneInnerWrapper,
  TextWrapper,
  PhoneWrapperText,
  MainTextPairing,
  RegistryBoxColumnLeft,
  RegistryBoxColumnLeftMiddle,
  RegistryBoxColumnRightMiddle,
  RegistryBoxColumnRight,
} from './OneStopShopSection.styles';
import { appPageData } from '@data-ts/pages/app';

export const OneStopShopSection: React.FC<{}> = () => {
  const oneStopShopData = appPageData.oneStopShop;
  const phoneContainerRef = useRef<HTMLDivElement>(null);
  const scrollController = useScrollController();
  const isMounted = useIsMounted();

  useLayoutEffect(() => {
    let scene: InstanceType<typeof ScrollMagic.Scene> | undefined;
    let oneStopShopTimeline: ReturnType<typeof gsap.timeline>;

    if (scrollController && isMounted) {
      const initTimeline = () => {
        if (scrollController) {
          oneStopShopTimeline = gsap.timeline({ paused: true, defaults: { ease: Linear.easeNone } });
          oneStopShopTimeline.fromTo(
            '#imageContainer1',
            {
              y: 0,
            },
            {
              y: -80,
            },
            0
          );
          oneStopShopTimeline.fromTo(
            '#imageContainer2',
            {
              y: 0,
            },
            {
              y: -80,
            },
            0
          );
          oneStopShopTimeline.fromTo(
            '#imageContainer3',
            {
              y: 0,
            },
            {
              y: -80,
            },
            0
          );
          oneStopShopTimeline.fromTo(
            '#imageContainer4',
            {
              y: 0,
            },
            {
              y: -80,
            },
            0
          );
          oneStopShopTimeline.fromTo(
            '#imageContainer5',
            {
              y: 0,
            },
            {
              y: -80,
            },
            0
          );
          oneStopShopTimeline.fromTo(
            phoneContainerRef.current,
            {
              y: 0,
            },
            {
              y: -40,
              duration: 1,
            },
            0
          );
        }
      };
      const initScene = () => {
        scene = new ScrollMagic.Scene({
          triggerElement: '#App_Registry_Cross',
          triggerHook: 'onCenter',
          duration: '200%',
        })
          .addTo(scrollController)
          .on('progress', (event: ScrollMagic.SceneProgressEvent) => {
            oneStopShopTimeline?.progress(event.progress);
          });
      };
      initTimeline();
      initScene();
    }
    const clearScene = () => {
      if (oneStopShopTimeline) {
        oneStopShopTimeline.progress(0);
        oneStopShopTimeline.kill();
      }
      if (scene) {
        scene.destroy(true);
      }
    };
    return clearScene;
  }, [scrollController, isMounted]);

  return (
    <Box backgroundColor="mono7" paddingTop={[10, 10, 12]} style={{ position: 'relative', zIndex: 3 }} id="App_Registry_Cross">
      <Box style={{ display: 'flex', justifyContent: 'flex-start', position: 'relative' }}>
        <RegistryBoxPositionWrapper>
          <TextWrapper marginTop={[12]}>
            <MainTextPairing
              alignment="left"
              eyebrow={oneStopShopData.eyebrow}
              eyebrowColor="red5"
              eyebrowVariant="sectionEyeBrow24"
              eyebrowTagName="h2"
              title={oneStopShopData.title}
              titleColor="red6"
              titleVariant="hed96Break"
              titleTagName="h3"
              body={oneStopShopData.content}
              bodyVariant="dek24"
              bodyColor="red6"
              bodyMaxWidth="283px"
            />
          </TextWrapper>
          <RegistryBoxWrapper>
            <RegistryBoxColumnLeft top="350px" mobiletop="60px" id="imageContainer1">
              <RegistryBox>
                <StyledCloudImage src="app/registry/crate-and-barrel-logo.svg" alt={oneStopShopData.crateAndBarrelAlt} />
              </RegistryBox>
              <RegistryBox>
                <StyledCloudImage src="app/registry/red-cross-logo.svg" alt={oneStopShopData.redCrossAlt} />
              </RegistryBox>
              <RegistryBox>
                <StyledCloudImage src="app/registry/snowe-logo.svg" alt={oneStopShopData.snoweAlt} />
              </RegistryBox>
            </RegistryBoxColumnLeft>
            <RegistryBoxColumnLeftMiddle top="" mobiletop="0px" style={{ zIndex: 5 }}>
              <PhoneWrapper ref={phoneContainerRef}>
                <PhoneInnerWrapper>
                  <PhoneWrapperText marginBottom={[8]}>
                    <TextPairing
                      alignment="center"
                      title={oneStopShopData.registry.title}
                      titleColor="red5"
                      titleVariant="hed40"
                      titleMaxWidth="265px"
                      titleTagName="h3"
                      body={oneStopShopData.registry.content}
                      bodyVariant="featureCopy17"
                      bodyColor="red5"
                      bodyMaxWidth="140px"
                    />
                  </PhoneWrapperText>
                  <Box id="imageContainer2">
                    <RegistryBox>
                      <StyledCloudImage src="app/registry/amazon-logo.svg" alt={oneStopShopData.amazonAlt} />
                    </RegistryBox>
                    <RegistryBox>
                      <StyledCloudImage src="app/registry/target-logo.svg" alt={oneStopShopData.targetAlt} />
                    </RegistryBox>
                  </Box>
                </PhoneInnerWrapper>
              </PhoneWrapper>
              <RegistryBox id="imageContainer3">
                <StyledCloudImage src="app/registry/west-elm-logo.svg" alt={oneStopShopData.westElmAlt} />
              </RegistryBox>
            </RegistryBoxColumnLeftMiddle>
            <RegistryBoxColumnRightMiddle top="675px" mobiletop="0px" style={{ zIndex: 3 }} id="imageContainer4">
              <RegistryBox>
                <StyledCloudImage src="app/registry/honeyfund-logo.svg" alt={oneStopShopData.honeyfundAlt} />
              </RegistryBox>
              <RegistryBox>
                <StyledCloudImage src="app/registry/stjude-logo.svg" alt={oneStopShopData.stjudeAlt} />
              </RegistryBox>
            </RegistryBoxColumnRightMiddle>
            <RegistryBoxColumnRight top="875px" mobiletop="420px" id="imageContainer5">
              <RegistryBox>
                <StyledCloudImage src="app/registry/bbb-logo.svg" alt={oneStopShopData.bbbAlt} />
              </RegistryBox>
            </RegistryBoxColumnRight>
          </RegistryBoxWrapper>
        </RegistryBoxPositionWrapper>
      </Box>
    </Box>
  );
};
