import React, { useState } from 'react';
import { CloudImage } from '@components/CloudImage';
import { Box } from '@components/Box';
import { Text } from '@components/typography';
import { LazyLoadWrapper } from '@components/LazyLoadWrapper';
import { CTALink } from '@components/CTALink';
import {
  IOSAndroidMobileTextBlock,
  IOSAndroidStyledFlex,
  IOSAndroidStyledRelativeColumn,
  IOSAndroidStyledTextPairing,
  IphoneWrapper,
  LottieAnimation,
  LottieContainer,
  LottieWrapper,
  ColumnWrapper,
  Phone,
  IphoneDeviceWrapper,
  TextColumnContainer,
} from './IOSAndroidSection.styles';
import HtmlParser from 'react-html-parser';
import { appPageData } from '@data-ts/pages/app';
import { VimeoVideo } from '@components/VimeoVideo';

export const IOSAndroidSection: React.FC<{}> = () => {
  const [isVisible, setIsVisible] = useState(false);
  const iosAndroidData = appPageData.iosAndroid;
  return (
    <Box backgroundColor="yellow1" paddingTop={[0, 10, 12]} paddingBottom={[10, 16]} id="App_Download">
      <LazyLoadWrapper beforeLoad={() => setIsVisible(true)}>
        <div />
      </LazyLoadWrapper>
      <ColumnWrapper>
        <IOSAndroidStyledRelativeColumn width="half">
          <IphoneDeviceWrapper>
            <IphoneWrapper>
              <LottieWrapper>
                <LottieContainer>
                  {isVisible ? (
                    <LottieAnimation style={{ width: '100%', height: '100%' }}>
                      <VimeoVideo
                        title="Guest Mobile App Video"
                        embedUrl="https://player.vimeo.com/video/472417945?h=8cb5d6b933&badge=0&player_id=0&app_id=58479"
                        posterUrl="app/couples-app/mobile-screen-schedule.jpg"
                        alt="Guest Mobile App Video"
                        background
                        loop
                      />
                    </LottieAnimation>
                  ) : null}
                </LottieContainer>
              </LottieWrapper>
            </IphoneWrapper>
          </IphoneDeviceWrapper>
          <Phone $isIphone={true} src="app/ios-android/screenshot_android.png" alt="iphone" width="100%" height="100%" />
        </IOSAndroidStyledRelativeColumn>
        <TextColumnContainer>
          <IOSAndroidStyledTextPairing
            eyebrow={HtmlParser(iosAndroidData.eyebrow)}
            eyebrowVariant="sectionEyeBrow24"
            eyebrowColor="red5"
            eyebrowTagName="h2"
            title={iosAndroidData.title}
            titleColor="red6"
            titleVariant="hed72Hero"
            titleTagName="h2"
            bodyColor="red6"
            bodyVariant="dek24"
            body={iosAndroidData.content}
          />
          <Box marginTop={['990px', 10, 10]}>
            <IOSAndroidMobileTextBlock marginBottom={6}>
              <Text color="red5" variant="calloutHed40Bold" tagName="h3">
                {iosAndroidData.download}
              </Text>
              <Text color="red6" variant="dek24" tagName="p">
                {iosAndroidData.joy}
              </Text>
            </IOSAndroidMobileTextBlock>
            <IOSAndroidStyledFlex flexDirection={['row', 'column']} justifyContent="center">
              <CTALink
                aria-label="Playstore"
                id="playstoreIOS"
                href="https://itunes.apple.com/app/joy-your-wedding-app/id994411720?mt=8&utm_source=iOS-app-cta-button&utm_medium=website&utm_campaign=iOS-app"
              >
                <CloudImage alt={iosAndroidData.altTextAppStore} src={'common/buttons/appstorenew.png'} />
              </CTALink>
              <CTALink
                aria-label="Googleplay"
                id="googleplayAndroid"
                href="https://play.google.com/store/apps/details?id=com.withjoy.joy&utm_source=android-app-cta-button&utm_medium=website&utm_campaign=android-app"
              >
                <CloudImage alt={iosAndroidData.altTextGooglePlay} src={'common/buttons/google-play-badge.png'} />
              </CTALink>
            </IOSAndroidStyledFlex>
          </Box>
        </TextColumnContainer>
      </ColumnWrapper>
    </Box>
  );
};
