import React from 'react';
import { FaqList } from '@components/FaqList';
import { Box } from '@components/Box';
import { Layout, Seo } from '@layout';
import { CenteredFaqSection } from '@sections/shared/CenteredFaqSection';
import {
  AppHeroAndGiveTheBestSection,
  IOSAndroidSection,
  OneStopShopSection,
  PhotoCollectionSection,
  TransportationAndTravelSection,
  WeddingScheduleSection,
} from '@sections/App';
import { appPageData } from '@data-ts/pages/app';
import { faqQuestionsData } from '@data-ts/components/FaqQuestions';
import { EasyForYouSection } from '@sections/shared/EasyForYouSection';
import { testimonialsData } from '@data-ts/components/Testimonials';
import { AbcSection } from '@sections/shared/AbcSection';
import TestimonialSection from '@sections/shared/TestimonialSection/TestimonialSection';

const guestPhotos = [
  'app/guest-photo-collection/app-guest-photos-landscape-1.jpg',
  'app/guest-photo-collection/app-guest-photos-portrait-1.jpg',
  'app/guest-photo-collection/app-guest-photos-landscape-2.jpg',
  'app/guest-photo-collection/app-guest-photos-portrait-2.jpg',
  'app/guest-photo-collection/app-guest-photos-landscape-3.jpg',
  'app/guest-photo-collection/app-guest-photos-portrait-3.jpg',
  'app/guest-photo-collection/app-guest-photos-portrait-4.jpg',
  'app/guest-photo-collection/app-guest-photos-landscape-4.jpg',
  'app/guest-photo-collection/app-guest-photos-landscape-5.jpg',
  'app/guest-photo-collection/app-guest-photos-landscape-6.jpg',
  'app/guest-photo-collection/app-guest-photos-landscape-7.jpg',
];

const PAGE: MarcomPageName = 'app';

const App = () => {
  return (
    <Layout
      isTransparentHeader={true}
      transparentJoyLogoColor="mono7"
      transparentHeaderButtonVariant="defaultPrimeWeddingWebsites"
      page={PAGE}
    >
      <Seo
        title={appPageData.seo.title}
        description={appPageData.seo.content}
        canonicalURL={'https://withjoy.com/app/'}
        ogImage={'common/meta_share/mobile-app.jpg'}
      />
      <AppHeroAndGiveTheBestSection />
      <WeddingScheduleSection />
      <TransportationAndTravelSection />
      <PhotoCollectionSection photos={guestPhotos} />
      <Box>
        <OneStopShopSection />
      </Box>
      <EasyForYouSection pageVariant="app" />
      <IOSAndroidSection />
      <CenteredFaqSection backgroundColor="yellow1" title={appPageData.faqTitle} titleColor="red6" sectionId="App_FAQ">
        <FaqList accordion={true} questionNodes={[...faqQuestionsData.guestApp, ...faqQuestionsData.general]} color="red6" />
      </CenteredFaqSection>
      <TestimonialSection carouselVariant="right" backgroundColor="red6" quotes={testimonialsData.mobileApp} mobileQuoteIndex={1} />
      <AbcSection abcSectionId="App_ABC_CTA" page={PAGE} />
    </Layout>
  );
};

export default App;
