import React, { useLayoutEffect, useRef, useState } from 'react';
import { JoykitIcon, JoykitIconProps } from '@components/JoykitIcon';
import { Box } from '@components/Box';
import { LazyLoadWrapper } from '@components/LazyLoadWrapper';
import { PhotoGrid } from '@components/PhotoGrid';
import { Columns } from '@components/Columns';
import { CloudImage } from '@components/CloudImage';
import { Text } from '@components/typography';
import {
  PhotoCollectionBottomAlignedColumns,
  PhotoCollectionIconsWrapper,
  FlexIconWrapper,
  PhoneFrameWrapper,
  ParagraphTextWrapper,
  PhotoServiceDetail,
  PhotoDetail,
  PhotoDetailBox,
  MainTextPairing,
} from './PhotoCollectionSection.styles';
import HtmlParser from 'react-html-parser';
import { useIsMounted } from '@hooks/useIsMounted';
import { useScrollController } from '@context/ScrollControllerProvider/useScrollController';
import ScrollMagic from 'scrollmagic';
import { gsap, Linear } from 'gsap';
import { useThemeMediaQuery } from '@hooks/useThemeMediaQuery';
import { appPageData } from '@data-ts/pages/app';
import { useTheme } from '@styledComponents';

interface PhotoCollectionIcon
  extends Readonly<{
    icon: string;
    text: string;
    detail: string;
  }> {}

const photosData = appPageData.photos;

const PhotoCollectionIcons: ReadonlyArray<PhotoCollectionIcon> = photosData.icons;

interface PhotoCollectionSectionProps
  extends Readonly<{
    photos: ReadonlyArray<string>;
  }> {}

export const PhotoCollectionSection: React.FC<PhotoCollectionSectionProps> = ({ photos }) => {
  const sectionContainerRef = useRef<HTMLDivElement>(null);
  const photoGridRef = useRef<HTMLDivElement>(null);
  const scrollController = useScrollController();
  const isMounted = useIsMounted();
  const theme = useTheme();
  const isMobileDevice = useThemeMediaQuery('below_sm');
  useLayoutEffect(() => {
    let scene: InstanceType<typeof ScrollMagic.Scene> | undefined;
    let photoGridTimeline: ReturnType<typeof gsap.timeline>;
    const sectionContainerEl = sectionContainerRef.current;
    const photoGridEl = photoGridRef.current;
    if (scrollController && isMounted && sectionContainerEl && photoGridEl && !isMobileDevice) {
      const initTimeline = () => {
        photoGridTimeline = gsap.timeline({ paused: true, ease: Linear.easeNone });
        photoGridTimeline.fromTo(
          photoGridEl,
          { scale: 1, height: '200%', y: '40%', x: '40%' },
          { scale: 1, height: '100%', y: '0%', x: '-10%' },
          0
        );
        photoGridTimeline.to(sectionContainerEl, { backgroundColor: theme.colors.yellow1 }, 0);
      };
      const initScene = () => {
        scene = new ScrollMagic.Scene({
          triggerElement: sectionContainerEl,
          triggerHook: 'onLeave',
          duration: '150%',
        })
          .addTo(scrollController)
          // workaround from here https://github.com/janpaepke/ScrollMagic/issues/918 until plugin for gsap 3 released https://github.com/janpaepke/ScrollMagic/pull/920 or we update it locally
          .on('progress', (event: ScrollMagic.SceneProgressEvent) => {
            photoGridTimeline?.progress(event.progress);
          });
      };
      initTimeline();
      initScene();
    }
    const clearScene = () => {
      if (photoGridTimeline) {
        photoGridTimeline.progress(0);
        photoGridTimeline.kill();
      }
      if (scene) {
        scene.destroy(true);
      }
    };
    return () => {
      clearScene();
    };
  }, [scrollController, isMounted, photoGridRef, sectionContainerRef, isMobileDevice, theme.colors.yellow1]);
  const [isAppearedInViewport, setIsAppearedInViewport] = useState(false);
  const renderIcons = (photoCollectionIcons: ReadonlyArray<PhotoCollectionIcon>) => {
    return photoCollectionIcons.map((icon, index) => {
      return (
        <FlexIconWrapper key={icon.icon + index} flexDirection="column" alignItems="flex-start" style={{ width: '100%' }}>
          <CloudImage src={icon.icon} width={64} height={64} />
          <div>
            <Text variant="featureHed17" tagName="span" color="red6" textAlign="center" marginTop={3}>
              {icon.text}
            </Text>
            <PhotoServiceDetail color="red6" variant="dek24" tagName="p">
              {icon.detail}
            </PhotoServiceDetail>
          </div>
        </FlexIconWrapper>
      );
    });
  };
  return (
    <Box backgroundColor="yellow1" paddingTop={13} marginBottom={[-10, 0]} ref={sectionContainerRef} id="App_Guest_Photo_Collection">
      <LazyLoadWrapper beforeLoad={() => setIsAppearedInViewport(true)}>
        <div></div>
      </LazyLoadWrapper>
      <PhotoCollectionBottomAlignedColumns centered={true} marginBottom={11}>
        <PhotoDetail width={6}>
          <PhotoDetailBox>
            <MainTextPairing
              eyebrow={photosData.eyebrow}
              eyebrowVariant="sectionEyeBrow24"
              eyebrowColor="red5"
              eyebrowTagName="h2"
              title={HtmlParser(photosData.title)}
              titleColor="red6"
              titleVariant="hed96Break"
              titleTagName="h3"
              bodyVariant="dek20"
            />
          </PhotoDetailBox>
        </PhotoDetail>
        <PhotoDetail width={6}>
          <ParagraphTextWrapper color="red6" variant="dek24" tagName="p" marginLeft={[0, 11]}>
            {photosData.content}
          </ParagraphTextWrapper>
        </PhotoDetail>
      </PhotoCollectionBottomAlignedColumns>
      <Columns centered={true}>
        <PhotoCollectionIconsWrapper spacing={8} vertical={false}>
          {renderIcons(PhotoCollectionIcons)}
        </PhotoCollectionIconsWrapper>
      </Columns>
      <Box style={{ position: 'relative', height: isMobileDevice ? 700 : 1046 }}>
        <Box ref={photoGridRef} style={{ transform: isMobileDevice ? 'scale(0.6) translate(0%, -33%)' : 'scale(1)' }}>
          <PhotoGrid photos={photos} backgroundColor="yellow1" isAppearedInViewport={isAppearedInViewport} />
        </Box>
        <PhoneFrameWrapper>
          <CloudImage src="app/guest-photo-collection/iphone_photos_frame_yellow2.svg" alt="phone frame" height="750px" />
        </PhoneFrameWrapper>
      </Box>
    </Box>
  );
};
