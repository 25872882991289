import React from 'react';
import { Box } from '@components/Box';
import { StyledColumns, StyledColumn, VideoOverlay } from './AbcSection.styles';
import { LazyLoadWrapper } from '@components/LazyLoadWrapper';
import { TextPairing } from '@components/TextPairing';
import { Flex } from '@components/Flex';
import { ABCSectionData, abcSectionData } from '@data-ts/shared/sections/abcSection.data';
import { GetStartedButton } from '@components/GetStartedButton/GetStartedButton';
import { VimeoVideo } from '@components/VimeoVideo';
import { DexecureOptimizationMode } from '@utils/cloudAssetUrl.util';

export const AbcSection: React.FC<{
  page?: MarcomPageName;
  abcSectionId?: string;
  customData?: ABCSectionData;
  posterOptimization?: DexecureOptimizationMode;
}> = ({ page, abcSectionId = 'planningShoesVideoContainer', customData, posterOptimization }) => {
  const data = customData ?? abcSectionData;

  return (
    <Box id={abcSectionId} style={{ height: '100vh', width: '100%', position: 'relative', overflow: 'hidden' }} backgroundColor="neutra6">
      <Box style={{ height: '100%', width: '100%', position: 'absolute', zIndex: 4 }}>
        <StyledColumns>
          <StyledColumn>
            <Flex alignItems={'center'} justifyContent={'center'} flexDirection={'column'} style={{ height: '100%' }}>
              <TextPairing
                title={data.title}
                body={data.content}
                alignment={'center'}
                titleColor={'purple1'}
                titleVariant={'hed72Hero'}
                titleTagName="h2"
                bodyColor={'purple1'}
                bodyVariant={'dek24'}
                marginBottom={[6, 9]}
              />
              <GetStartedButton id="planningShoes" variant="darkGetStartedBtn" page={page}>
                {data.link!.label}
              </GetStartedButton>
            </Flex>
          </StyledColumn>
        </StyledColumns>
      </Box>
      <LazyLoadWrapper>
        <VideoOverlay />
        <VimeoVideo
          title={data.video.title}
          embedUrl={data.video.embedUrl}
          posterUrl={data.video.posterUrl}
          alt={data.video.title}
          cover
          background
          loop
          posterOptimization={posterOptimization}
        />
      </LazyLoadWrapper>
    </Box>
  );
};
