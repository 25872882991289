import styled from 'styled-components';
import { themeColor, themeMediaQuery, themeSpace } from '@utils/styledTheme.util';
import { Box } from '@components/Box';
import { Columns } from '@components/Columns';
import { cloudAssetUrl } from '@utils/cloudAssetUrl.util';
import { defaultBase64Placeholder } from '@components/CloudImage';
import { TextPairing } from '@components/TextPairing';

export const TravelMapsContainer = styled.div`
  height: 400px;
  width: 800px;
  top: 25%;
  left: 50%;
  margin-left: -400px;
  position: absolute;
  ${themeMediaQuery('below_sm')} {
    width: 450px;
    margin-left: -130px;
    top: 30%;
  }
`;

export const PhoneContainer = styled.div`
  width: 416px;
  height: 870px;
  position: absolute;
  top: 120px;
  left: 50%;
  margin-left: -208px;
`;

export const Phone = styled.div`
  width: 100%;
  height: 100%;
  border: 8px solid #ffffff;
  box-shadow: 0px 24px 48px #ffd7d4;
  border-radius: 64px;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  ${themeMediaQuery('below_sm')} {
    width: 90%;
  }
`;

const initialMapScale = 1.7;
export const BackgroundImageContainer = styled(Box)<{ isScrolledIntoView: boolean }>`
  position: relative;
  height: 975px;
  width: 100%;
  background: linear-gradient(#fffbf7 0%, rgba(255, 251, 247, 0) 14%, rgba(255, 251, 247, 0) 87%, rgba(255, 251, 247, 1) 100%),
    ${themeColor('mono7')}
      url(${({ isScrolledIntoView }) =>
        isScrolledIntoView ? cloudAssetUrl('wedding-website/guest-travel-tools/travel-background_3.svg') : defaultBase64Placeholder})
      center/cover;
`;

export const TransportationAndTravelImagesColumn = styled(Columns.Column)`
  z-index: 1;
`;

export const TransportationAndTravelImagesBlock = styled(Box)`
  display: flex;
  height: 100%;
  flex-direction: column;
  justify-content: flex-end;
  min-height: 300px;
  svg {
    ${themeMediaQuery('below_sm')} {
      width: 90%;
    }
  }
  svg:nth-child(2) {
    width: 100%;
    margin-top: -30px;
    margin-left: ${themeSpace(6)};
    ${themeMediaQuery('sm')} {
      margin-bottom: -30px;
      margin-left: ${themeSpace(10)};
    }
    ${themeMediaQuery('below_sm')} {
      width: 90%;
    }
  }
`;

export const MainTextPairing = styled(TextPairing)`
  ${themeMediaQuery('below_sm')} {
    position: absolute;
    z-index: 1;
    left: 0;
    right: 0;
    top: 250px;
  }
  .text-pairing-title {
    max-width: 601px;
    ${themeMediaQuery('below_sm')} {
      max-width: 280px;
      margin-bottom: 2rem;
    }
    ${themeMediaQuery('xl')} {
      max-width: 830px;
    }
  }
  .text-pairing-body {
    max-width: 412px;
    ${themeMediaQuery('below_sm')} {
      max-width: 320px;
    }
  }
`;

export const TransportationMainBox = styled(Box)`
  position: relative;
`;
