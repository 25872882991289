import Carousel from 'nuka-carousel';
import { marcomTheme } from '@components/themes';
import { Text } from '@components/typography';
import styled from 'styled-components';
import { themeMediaQuery, themeSpace } from '@utils/styledTheme.util';
import { Box } from '@components/Box';
import { Columns } from '@components/Columns';
import { cloudAssetUrl } from '@utils/cloudAssetUrl.util';

export const TestimonialContainer = styled(Box)<{ carouselVariant: string }>`
  width: 100%;
  display: flex;
  flex-direction: ${({ carouselVariant }) => (carouselVariant === 'right' ? 'row-reverse' : 'row')};
  min-height: 800px;
  ${themeMediaQuery('below_sm')} {
    flex-direction: ${({ carouselVariant }) => (carouselVariant === 'right' ? 'column' : 'column-reverse')};
    min-height: unset;
  }
`;

export const DetailWrapper = styled.div`
  width: 50%;
  ${themeMediaQuery('below_sm')} {
    width: 100%;
  }
`;

export const TestimonialDetails = styled(Columns)`
  height: 100%;
  ${themeMediaQuery('below_sm')} {
    height: auto;
  }
`;

export const TestimonialContent = styled(Columns.Column)`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: ${themeSpace(11)};
  ${themeMediaQuery('below_sm')} {
    height: auto;
    padding: ${themeSpace(0)};
    padding-top: ${themeSpace(7)};
    padding-bottom: ${themeSpace(7)};
  }
`;

export const TestimonialAsset = styled(Box)<{ src: string }>`
  width: 50%;
  background-image: url(${({ src }) => cloudAssetUrl(src, { optimization: 'default' })});
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  ${themeMediaQuery('below_sm')} {
    width: 100%;
    height: 100vw;
  }
`;

export const TitleWrapper = styled.div`
  width: 100%;
`;

export const StyledQuotesContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  ${themeMediaQuery('below_sm')} {
    padding-top: 30px;
  }
`;

export const DateWrapper = styled(Text)`
  margin-bottom: 41px;
  ${themeMediaQuery('below_sm')} {
    margin-bottom: 15px;
  }
`;
