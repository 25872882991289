import styled from 'styled-components';
import { themeMediaQuery, themeColor, themeSpace } from '@utils/styledTheme.util';
import { Columns } from '@components/Columns';
import { TextPairing } from '@components/TextPairing';
import { Flex } from '@components/Flex';
import { Box } from '@components/Box';
import { CloudImage } from '@components/CloudImage';

export const Phone = styled(CloudImage)<{ $isIphone?: boolean }>`
  border: 8px solid #ffffff;
  box-shadow: 0px 24px 48px #ffd7d4;
  border-radius: 64px;
  width: 416px;
  height: 830px;
  position: absolute;
  right: ${props => (props.$isIphone ? '600px' : '150px')};
  top: ${props => (props.$isIphone ? '-90px' : '-50px')};

  ${themeMediaQuery('below_sm')} {
    width: 90%;
    top: 330px;
    right: ${props => (props.$isIphone ? 'unset' : '-208px')};
    left: ${props => (props.$isIphone ? '-208px' : 'unset')};
    display: none;
  }
  ${themeMediaQuery('xl')} {
    width: 450px;
    height: 870px;
    right: ${props => (props.$isIphone ? '640px' : '-150px')};
  }
`;

export const IphoneWrapper = styled.div<{ $isIphone?: boolean }>`
  border: 8px solid #ffffff;
  box-shadow: 0px 24px 48px #ffd7d4;
  border-radius: 64px;
  width: 416px;
  height: 874px;
  position: absolute;
  overflow: hidden;
  right: ${props => (props.$isIphone ? '600px' : '150px')};
  top: ${props => (props.$isIphone ? '-90px' : '-50px')};
  
  ${themeMediaQuery('below_sm')} {
    border-radius: 54px;
    width: 100%;
    top: 410px;
    right: ${props => (props.$isIphone ? 'unset' : '-208px')};
    left: ${props => (props.$isIphone ? '-208px' : 'unset')};
    display; none;
    left: 0px;
    height: 700px;
  }
  ${themeMediaQuery('xl')} {
    width: 490px;
    height: 870px;
    right: 70px;
    top: -10px;
  }
`;

export const IphoneDeviceWrapper = styled(Box)`
  ${themeMediaQuery('below_sm')} {
    width: 100%;
    position: absolute;
    left: 0px;
  }
`;

export const ColumnWrapper = styled(Columns)`
  ${themeMediaQuery('below_sm')} {
    display: flex;
    flex-direction: column-reverse;
    position: relative;
  }
`;

export const IOSAndroidStyledRelativeColumn = styled(Columns.Column)`
  position: relative;
  ${themeMediaQuery('below_sm')} {
    position: absolute;
    left: 0;
    width: 100%;
    top: -90px;
  }
`;

export const IOSAndroidStyledTextPairing = styled(TextPairing)`
  p {
    text-align: left;
    ${themeMediaQuery('below_sm')} {
      text-align: center;
      max-width: 100% !important;
    }
  }
  .text-pairing-title {
    max-width: 272px;
    ${themeMediaQuery('below_sm')} {
      text-align: center;
      max-width: 100%;
    }
    ${themeMediaQuery('xl')} {
      max-width: 340px;
    }
  }
  .text-pairing-body {
    max-width: 290px;
    ${themeMediaQuery('xl')} {
      max-width: 350px;
    }
  }
`;

export const IOSAndroidStyledFlex = styled(Flex)`
  img {
    width: 240px;
    ${themeMediaQuery('below_sm')} {
      width: 160px;
    }
    &:first-of-type {
      margin-bottom: 45px;
      margin-right: 0;
      ${themeMediaQuery('below_sm')} {
        margin-bottom: 0;
        margin-right: 11px;
      }
    }
  }
`;

export const IOSAndroidMobileTextBlock = styled(Box)`
  display: none;
  ${themeMediaQuery('below_sm')} {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
`;

export const LottieContainer = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  overflow: hidden;
  border-radius: 50px;
  background: ${themeColor('mono7')};
`;

export const LottieWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`;

export const LottieAnimation = styled.div`
  width: 99% !important;
  margin: 0 auto;
  height: 100%;
  ${themeMediaQuery('below_sm')} {
    width: 97% !important;
  }
  clip-path: polygon(
    22% 0%,
    12% 0%,
    0% 0%,
    0% 0%,
    0% 0%,
    0% 0%,
    0% 0%,
    0% 100%,
    100% 100%,
    100% 0%,
    100% 0%,
    75% 0.2%,
    74% 2.9%,
    73% 3.4%,
    72% 3.8%,
    71% 3.9%,
    70% 4%,
    30% 4%,
    29% 3.9%,
    28% 3.8%,
    27% 3.4%,
    26% 2.9%,
    25% 0%
  );
`;

export const TextColumnContainer = styled(Columns.Column)`
  ${themeMediaQuery('xl')} {
    padding-left: ${themeSpace(13)};
  }
`;
