import React, { useLayoutEffect, useRef, useState } from 'react';
import { gsap, Linear } from 'gsap';
import { useIsMounted } from '@hooks/useIsMounted';
import { useScrollController } from '@context/ScrollControllerProvider/useScrollController';
import ScrollMagic from 'scrollmagic';
import {
  GradientWrap,
  LottieContainer,
  LottieWrapper,
  PairedText,
  SectionCloudImage,
  SectionContainer,
  TextPairingWrapper,
  SectionPlaceholderCloudImage,
  LazyLoadWrapperPlaceholder,
} from './EasyForYouSection.styles';
import { cloudAssetUrl } from '@utils/cloudAssetUrl.util';
import { LazyLoadWrapper } from '@components/LazyLoadWrapper';
import { easyForYouData } from '@data-ts/shared/sections/EasyForYouSection';
import ReactHtmlParser from 'react-html-parser';
import { useLottieAnimation } from '@hooks/useLottieAnimation';
import { PhotoCredit } from '@components/PhotoCredit';

enum pageVariantMap {
  'weddingInvitations',
  'app',
  'registry',
}

export type PageVariant = keyof typeof pageVariantMap;

interface EasyForYouSectionProps {
  pageVariant: PageVariant;
  lottieBackground?: string;
}

export const EasyForYouSection: React.FC<EasyForYouSectionProps> = ({ pageVariant, lottieBackground }) => {
  const [isScrolledIntoView, setIsScrolledIntoView] = useState(false);
  const easyForYouLottieRef = useRef<HTMLDivElement>(null);
  const easyForYouContainerRef = useRef<HTMLDivElement>(null);
  const scrollController = useScrollController();
  const isMounted = useIsMounted();

  const getLottieURL = () => {
    switch (pageVariant) {
      case 'app':
        return 'sitewide/block_emotional-break/easyforyou3/data.json';
      case 'registry':
        return 'sitewide/block_emotional-break/easyforyou4/data.json';
      default:
        return 'sitewide/block_emotional-break/easyforyou1/data.json';
    }
  };

  const getBackgroundImage = () => {
    switch (pageVariant) {
      case 'app':
        return 'sitewide/block_emotional-break/easyforyou3/easyforyou_bg.jpg';
      case 'registry':
        return 'sitewide/block_emotional-break/easyforyou4/easyforyou_bg.jpg';
      default:
        return 'sitewide/block_emotional-break/easyforyou1/easyforyou_bg.jpg';
    }
  };

  const { animationRef, loadAnimation } = useLottieAnimation(
    {
      path: cloudAssetUrl(getLottieURL()), // Required
      renderer: 'svg', // Required
      loop: false, // Optional
      autoplay: false, // Optional
      name: 'easyForYouSectionAnimation',
      rendererSettings: {
        progressiveLoad: false,
        preserveAspectRatio: 'xMidYMid slice',
      },
    },
    { containerRef: easyForYouLottieRef }
  );

  useLayoutEffect(() => {
    let scene: InstanceType<typeof ScrollMagic.Scene> | undefined;
    let livestreamTimeline: ReturnType<typeof gsap.timeline>;

    if (scrollController && isMounted) {
      const initTimeline = () => {
        livestreamTimeline = gsap.timeline({ paused: true });
        livestreamTimeline.to(
          { frame: 0 },
          1,
          {
            frame: 1,
            onUpdate: function() {
              animationRef.current?.goToAndStop(Math.round(this.progress() * 28), true);
            },
            ease: Linear.easeNone,
          },
          0
        );
      };
      const startScrollAnimation = () => {
        scene = new ScrollMagic.Scene({
          triggerElement: '#EasyForYouSection',
          triggerHook: 'onEnter',
          duration: '400%',
        })
          .addTo(scrollController)
          .on('progress', (event: ScrollMagic.SceneProgressEvent) => {
            livestreamTimeline?.progress(event.progress);
          });
      };
      initTimeline();
      startScrollAnimation();
    }
    const clearScene = () => {
      if (livestreamTimeline) {
        livestreamTimeline.progress(0);
        livestreamTimeline.kill();
      }
      if (scene) {
        scene.destroy(true);
      }
    };
    return clearScene;
  }, [scrollController, isMounted, animationRef]);

  return (
    <SectionContainer pageVariant={pageVariant} ref={easyForYouContainerRef} id="EasyForYouSection">
      <LazyLoadWrapper
        beforeLoad={() => {
          loadAnimation();
          setIsScrolledIntoView(true);
        }}
        placeholder={<LazyLoadWrapperPlaceholder />}
      >
        <div />
      </LazyLoadWrapper>
      {pageVariant !== 'registry' ? <GradientWrap /> : null}
      <SectionPlaceholderCloudImage src={getBackgroundImage()} alt="easyForYou" isMainSrcShown={isScrolledIntoView} />
      {pageVariant !== 'registry' ? (
        <PairedText>
          <TextPairingWrapper
            alignment="center"
            eyebrow={easyForYouData.eyebrow}
            eyebrowVariant="sectionEyeBrow24"
            eyebrowColor="mono7"
            eyebrowMaxWidth="566px"
            eyebrowTagName="h2"
            title={ReactHtmlParser(easyForYouData.title)}
            titleVariant={'hed120SuperEmphasis'}
            titleColor="mono7"
            titleTagName="h2"
            body={easyForYouData.content}
            bodyVariant="dek24"
            bodyColor="mono7"
          />
        </PairedText>
      ) : null}
      <LottieWrapper pageVariant={pageVariant}>
        {isScrolledIntoView ? (
          <LottieContainer ref={easyForYouLottieRef} backgroundImage={lottieBackground} />
        ) : (
          <LottieContainer ref={easyForYouLottieRef} />
        )}
      </LottieWrapper>
      <PhotoCredit
        color="yellow1"
        hoverColor="yellow2"
        activeColor="yellow3"
        leftIconVariant
        offset="bottom-left"
        id="photoCreditEasyForYou"
      />
    </SectionContainer>
  );
};
