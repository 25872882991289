import React from 'react';
import { styled } from '@styledComponents';
import { JoykitColor } from '@components/themes';
import { SpacingStack } from '@components/SpacingStack';
import { Box, BoxProps } from '@components/Box';
import { Text } from '@components/typography';
import { themeMediaQuery } from '@utils/styledTheme.util';

interface CenteredFaqSectionProps
  extends Readonly<{
    backgroundColor: BoxProps['backgroundColor'];
    title: string;
    titleColor: JoykitColor;
    sectionId?: string;
  }> {}

const FaqQuestionWrapper = styled(Box)`
  margin: auto;
  max-width: 600px;
  ${themeMediaQuery('xl')} {
    max-width: 770px;
  }
`;

export const CenteredFaqSection: React.FC<CenteredFaqSectionProps> = ({ children, backgroundColor, title, titleColor, sectionId }) => {
  const id = sectionId ? sectionId : 'CenteredFaqSection';
  return (
    <Box
      backgroundColor={backgroundColor}
      paddingTop={[14]}
      paddingBottom={[13]}
      style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
      id={id}
    >
      <SpacingStack spacing={[11]}>
        <Text color={titleColor} tagName="h2" variant="hed72Hero" style={{ textAlign: 'center', maxWidth: '666px' }}>
          {title}
        </Text>
        <FaqQuestionWrapper>{children}</FaqQuestionWrapper>
      </SpacingStack>
    </Box>
  );
};
