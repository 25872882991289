import styled from 'styled-components';
import { Box } from '@components/Box';
import { CloudImage } from '@components/CloudImage';
import { TextPairing } from '@components/TextPairing';
import { themeColor, themeMediaQuery, themeSpace } from '@utils/styledTheme.util';

export const RegistryBox = styled(Box)<{ transparent?: boolean }>`
  box-shadow: ${props => (props.transparent ? undefined : '0px 8px 20px rgba(255, 215, 212, 0.4)')};
  border-radius: 32px;
  background-color: ${themeColor('mono7')};
  width: 272px;
  height: 272px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${props => (props.transparent ? 'transparent' : undefined)};
  margin-bottom: 48px;
  padding: 48px 24px;
  ${themeMediaQuery('below_sm')} {
    width: 159px;
    height: 159px;
    padding: 30px 16px;
  }
`;

export const StyledCloudImage = styled(CloudImage)`
  width: 100%;
  height: 100%;
  object-fit: contain;
`;

export const RegistryBoxColumn = styled(Box)<{
  top?: string;
  mobiletop?: string;
}>`
  display: flex;
  flex-direction: column;
  position: absolute;
  align-items: center;
  top: ${props => props.top};
  ${themeMediaQuery('below_sm')} {
    top: ${props => props.mobiletop};
  }
`;

export const RegistryBoxColumnLeft = styled(RegistryBoxColumn)`
  right: -30px;
  ${themeMediaQuery('below_sm')} {
    right: -20%;
  }
  @media (min-width: 36rem) and (max-width: 52.063rem) {
    right: 8%;
  }
  ${themeMediaQuery('sm')} {
    right: -175px;
  }
  ${themeMediaQuery('md')} {
    right: -30px;
  }
`;

export const RegistryBoxColumnLeftMiddle = styled(RegistryBoxColumn)`
  right: 275px;
  ${themeMediaQuery('below_sm')} {
    right: 78%;
  }
  @media (min-width: 36rem) and (max-width: 52.063rem) {
    right: 72%;
  }
  ${themeMediaQuery('sm')} {
    right: 70px;
  }
  ${themeMediaQuery('md')} {
    right: 275px;
  }
`;

export const RegistryBoxColumnRightMiddle = styled(RegistryBoxColumn)`
  right: 700px;
  ${themeMediaQuery('below_sm')} {
    right: 29%;
  }
  @media (min-width: 36rem) and (max-width: 52.063rem) {
    right: 40%;
  }
  ${themeMediaQuery('sm')} {
    right: 495px;
  }
  ${themeMediaQuery('md')} {
    right: 700px;
  }
`;

export const RegistryBoxColumnRight = styled(RegistryBoxColumn)`
  right: 1048px;
  ${themeMediaQuery('below_sm')} {
    right: 29%;
  }
  @media (min-width: 36rem) and (max-width: 52.063rem) {
    right: 40%;
  }
  ${themeMediaQuery('sm')} {
    right: 843px;
  }
  ${themeMediaQuery('md')} {
    right: 1048px;
  }
`;

export const RegistryBoxPositionWrapper = styled.div`
  display: flex;
  position: relative;
  width: 100%;
  height: 1400px;
  margin: auto;
  max-width: 1440px;
  ${themeMediaQuery('below_sm')} {
    height: 1140px;
  }
`;

export const RegistryBoxWrapper = styled.div`
  display: flex;
  position: absolute;
  right: -5%;
  z-index: 0;
  ${themeMediaQuery('below_sm')} {
    right: 0%;
    z-index: 0;
    top: 500px;
    width: 100%;
  }
`;

export const PhoneInnerWrapper = styled.div`
  width: 445px;
  height: 898px;
  background: linear-gradient(180deg, #ffffff 0%, #fff9f9 100%);
  box-shadow: inset 0px -8px 20px #f7cbc8;
  border-radius: 58px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  padding-top: 60px;
  background: transparent;
  box-shadow: unset;
  ${themeMediaQuery('below_sm')} {
    width: auto;
    height: auto;
  }
`;

export const PhoneWrapper = styled.div`
  background: #ffd7d4;
  box-shadow: 0px 32px 64px #ffd7d4;
  border-radius: 58px;
  margin-bottom: 12px;
  background: transparent;
  box-shadow: unset;
  ${themeMediaQuery('below_sm')} {
    transform: unset !important;
    margin-bottom: 0px;
  }
`;

export const TextWrapper = styled(Box)`
  position: relative;
  margin-left: ${themeSpace(14)};
  ${themeMediaQuery('below_sm')} {
    margin-left: ${themeSpace(8)};
    margin-right: ${themeSpace(5)};
    margin-top: ${themeSpace(9)};
  }
  ${themeMediaQuery('sm')} {
    margin-left: ${themeSpace(10)};
  }
  ${themeMediaQuery('md')} {
    margin-left: ${themeSpace(14)};
  }
`;

export const PhoneWrapperText = styled(Box)`
  ${themeMediaQuery('below_sm')} {
    display: none;
  }
`;

export const MainTextPairing = styled(TextPairing)`
  .text-pairing-title {
    max-width: 365px;
    ${themeMediaQuery('xl')} {
      max-width: 510px;
    }
  }
`;
