import styled from 'styled-components';
import { Columns } from '@components/Columns';
import { TextPairing } from '@components/TextPairing';
import { themeMediaQuery, themeColor, themeSpace } from '@utils/styledTheme.util';
import { Box } from '@components/Box';
import { CloudImage, PlaceholderCloudImage, defaultBase64Placeholder } from '@components/CloudImage';
import { cloudAssetUrl } from '@utils/cloudAssetUrl.util';
import Carousel from 'nuka-carousel';
import { Flex } from '@components/Flex';
import { CTALink } from '@components/CTALink';

export const HeroSectionWrapper = styled(Box)`
  height: 100%;
`;

export const BannerWrapper = styled(Box)`
  position: absolute;
  height: 88%;
  width: 86%;
  top: 10px;
  left: 7%;
  overflow: hidden;
  border-radius: 50px;
`;

export const HeroOverlayBox = styled(Box)`
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  width: 100%;
  height: 70%;
  background: linear-gradient(180deg, #000000 3.17%, rgba(0, 0, 0, 0) 35.3%),
    linear-gradient(16.43deg, rgba(0, 0, 0, 0.7) 21.86%, rgba(0, 0, 0, 0) 57.63%);
`;

export const HeroOverlayBox2 = styled(Box)`
  position: absolute;
  left: 0;
  bottom: 0;
  z-index: 1;
  width: 100%;
  height: 30%;
  background: linear-gradient(180deg, #0e090f 0%, rgba(14, 9, 15, 0.69) -6%, rgba(14, 9, 15, 0.2) 80.73%, rgba(14, 9, 15, 0) 100%);
`;

export const HeroOverlayBox3 = styled(Box)`
  position: absolute;
  left: 0;
  bottom: 0;
  z-index: 2;
  width: 100%;
  height: 20%;
  background: linear-gradient(180deg, #0e090f 0%, rgba(14, 9, 15, 1) 18.02%, rgba(33, 28, 33, 1) 100%);
`;

export const HeroImageWrapper = styled(Box)`
  position: relative;
`;

export const HeroWrapper = styled(Box)<{ isVisible: boolean }>`
  position: relative;
  min-height: 100vh;
  background-image: url(${props =>
    props.isVisible ? cloudAssetUrl('app/hero/hero.jpg', { optimization: 'aggressive' }) : defaultBase64Placeholder});
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center top -15vw;
`;

export const HeroSectionTextWrapper = styled(Flex)`
  width: 100%;
  text-align: center;
  flex-direction: column;
  align-items: center;
`;

export const HeroImageContentSchedule = styled(PlaceholderCloudImage)`
  background-repeat: no-repeat;
  background-size: cover;
  overflow: hidden;
  object-fit: contain;
  position: absolute;
  top: 0;
  clip-path: polygon(
    22% 0%,
    12% 0%,
    0% 0%,
    0% 0%,
    0% 0%,
    0% 0%,
    0% 0%,
    0% 100%,
    100% 100%,
    100% 0%,
    100% 0%,
    75% 0.2%,
    74% 2.9%,
    73% 3.4%,
    72% 3.8%,
    71% 3.9%,
    70% 4%,
    30% 4%,
    29% 3.9%,
    28% 3.8%,
    27% 3.4%,
    26% 2.9%,
    25% 0%
  );
`;

export const HeroTextWrapper = styled(Columns)`
  position: absolute;
  left: 0px;
  right: 0;
  width: 100%;
  top: 40%;
  z-index: 3;
`;

export const GiveTheTextPairing = styled(TextPairing)`
  .text-pairing-title {
    max-width: 390px;
  }
  .text-pairing-body {
    max-width: 550px;
  }
  ${themeMediaQuery('below_sm')} {
    .text-pairing-title {
      max-width: 310px;
    }
    .text-pairing-body {
      max-width: 320px;
    }
  }
  ${themeMediaQuery('xl')} {
    .text-pairing-title {
      max-width: 510px;
    }
    .text-pairing-body {
      max-width: 550px;
    }
  }
`;

export const HostGoTextPairing = styled(TextPairing)`
  .text-pairing-title {
    max-width: 550px;
  }
  .text-pairing-body {
    max-width: 342px;
  }
  ${themeMediaQuery('below_sm')} {
    .text-pairing-title {
      max-width: 320px;
    }
    .text-pairing-body {
      max-width: 300px;
    }
  }
  ${themeMediaQuery('xl')} {
    .text-pairing-body {
      max-width: 390px;
    }
  }
`;

export const ParaGraphWrapper = styled(TextPairing)`
  .text-pairing-body {
    max-width: 240px;
  }
`;

export const HostGoWrapper = styled(Box)`
  position: absolute;
  left: -42%;
  top: 200px;
  opacity: 0;
`;

export const IphoneWrapper = styled(Box)`
  position: absolute;
  z-index: 1;
  ${themeMediaQuery('sm')} {
    transform: translate(-111px, 0px);
  }
  ${themeMediaQuery('md')} {
    transform: translate(0px, 0px);
  }
`;

export const IphoneShellWrapper = styled(Box)`
  position: relative;
  width: 360px;
`;

export const YellowIphoneShell = styled(Box)`
  width: 100%;
`;

export const RedIphoneShell = styled(Box)`
  width: 100%;
  position: absolute;
  top: 0px;
  opacity: 0;
`;

export const HostDataColumns = styled(Columns.Column)`
  width: 56vw;
  max-width: 645px;
`;

export const GiveDataColumns = styled(Columns.Column)`
  width: 53vw;
  max-width: 600px;
`;

/* ----- Mobile Styling ----- */

export const HeroMobileSectionWrapper = styled(Box)<{
  backgroundImage: string;
}>`
  background: linear-gradient(22.43deg, rgba(33, 28, 33, 1) 21.86%, rgba(0, 0, 0, 0) 60.63%),
    url(${props => cloudAssetUrl(props.backgroundImage, { optimization: 'aggressive' })});
  background-repeat: no-repeat;
  background-position-y: -101px;
  background-position-x: center;
  background-size: cover;
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex-direction: column;
`;

export const MobileHeroTextWrapper = styled(Box)``;
export const SectionMobileWrapper = styled(Box)``;
export const SectionImageWrapper = styled(Box)`
  position: relative;
  width: 360px;
  left: 50%;
  transform: translateX(-50%);
  overflow: hidden;
  border-radius: 50px;
`;
export const MobileShellContainer = styled(Box)`
  height: 300px;
  overflow: hidden;
`;
export const MobileIphoneWrapper = styled(CloudImage)`
  width: 100%;
`;

export const HeroImageMobileContentSchedule = styled(CloudImage)`
  overflow: hidden;
  object-fit: contain;
  clip-path: polygon(
    22% 0%,
    12% 0%,
    0% 0%,
    0% 0%,
    0% 0%,
    0% 0%,
    0% 0%,
    0% 100%,
    100% 100%,
    100% 0%,
    100% 0%,
    75% 0.2%,
    74% 2.9%,
    73% 3.4%,
    72% 3.8%,
    71% 3.9%,
    70% 4%,
    30% 4%,
    29% 3.9%,
    28% 3.8%,
    27% 3.4%,
    26% 2.9%,
    25% 0%
  );
  width: 100%;
`;

export const StyledCarousel = styled(Carousel)<{ selectedSlide: number }>`
  height: 300px !important;
  max-height: 300px;
  .slider-list,
  .slider-slide {
    cursor: default !important;
  }

  ul > li:nth-child(${props => props.selectedSlide + 1}) {
    z-index: -1 !important;
  }
  .slider-control-bottomcenter {
    left: 32% !important;
    ul {
      top: 5px !important;
    }
  }
  .paging-item {
    button {
      margin-right: 20px !important;
      opacity: 1 !important;
      width: 12px;
      height: 12px;
      border-radius: 50%;
      fill: transparent !important;
      border: 2px solid ${themeColor('red5')} !important;
    }
    &.active {
      button {
        background: ${themeColor('red5')} !important;
      }
    }
  }
`;

export const HeroMobileScheduleImage = styled(Box)`
  position: absolute;
  top: 0px;
  width: 90%;
  height: 300px;
  left: 50%;
  transform: translateX(-50%);
  border-radius: 50px;
  overflow: hidden;
  top: 58px;
`;

export const BoxContainer = styled(Box)`
  width: 100%;
  ${themeMediaQuery('sm')} {
    #HeroAndGiveTheGuestMobile {
      display: none;
    }
  }
  ${themeMediaQuery('below_sm')} {
    #HeroAndGiveTheGuest {
      display: none;
    }
  }
`;

export const IOSAndroidStyledFlex = styled(Flex)`
  ${themeMediaQuery('below_sm')} {
    img {
      height: 70px;
      width: auto;
    }
  }
  ${themeMediaQuery('sm')} {
    > :not(:first-child) {
      margin-left: ${themeSpace(6)};
    }
  }
`;

export const HeroMobileSectionTextPair = styled(Box)`
  padding-top: 5rem;
  transform: translateY(-75px);
`;

export const StyledIOSAppCTALink = styled(CTALink)`
  @supports not (-webkit-touch-callout: none) {
    display: none;
  }
`;

export const StyledAndroidAppCTALink = styled(CTALink)`
  @supports (-webkit-touch-callout: none) {
    display: none;
  }
`;
