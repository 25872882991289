import styled from 'styled-components';
import { JoykitColor } from '@components/themes';
import { themeColor, themeMediaQuery } from '@utils/styledTheme.util';

export const InactiveCarouselIndicatorDot = styled.div<{ color?: JoykitColor }>`
  width: 12px;
  height: 12px;
  border: 2px solid ${props => (props.color ? themeColor(props.color) : themeColor('purple4'))};
  border-radius: 50%;
  ${themeMediaQuery('below_sm')} {
    width: 12px;
    height: 12px;
  }
`;

export const ActiveCarouselIndicatorDot = styled.div<{ color?: JoykitColor }>`
  width: 12px;
  height: 12px;
  border: 1px solid ${props => (props.color ? themeColor(props.color) : themeColor('purple4'))};
  border-radius: 50%;
  background: ${props => (props.color ? themeColor(props.color) : themeColor('purple4'))};
  ${themeMediaQuery('below_sm')} {
    width: 12px;
    height: 12px;
  }
`;
