import React from 'react';
import { Box } from '@components/Box';
import { Container, ImgContainer, Wrapper, SecondContainer } from './PhotoGrid.styles';
import { MarcomBackgroundColorProps } from 'styled-system';
import { cloudAssetUrl } from '@utils/cloudAssetUrl.util';
import { useThemeMediaQuery } from '@hooks/useThemeMediaQuery';
import { defaultBase64Placeholder } from '@components/CloudImage';

export interface PhotoNode
  extends Readonly<{
    node: Readonly<{
      src: string;
    }>;
  }> {}

interface PhotoGridProps
  extends Readonly<{
    photos: ReadonlyArray<string>;
    backgroundColor?: MarcomBackgroundColorProps['backgroundColor'];
    isAppearedInViewport: boolean;
    imageProperty?: {
      width: number;
      height: number;
    }[];
  }> {}

export const PhotoGrid = React.forwardRef<HTMLDivElement, PhotoGridProps>(
  ({ photos, backgroundColor, isAppearedInViewport, imageProperty }, ref) => {
    const imgContainerBg = (path: string) => {
      return isAppearedInViewport ? cloudAssetUrl(path) : defaultBase64Placeholder;
    };
    const isMobileDevice = useThemeMediaQuery('below_sm');

    const imagesInfo = imageProperty || [
      { width: 476, height: 317 },
      { width: 239, height: 359 },
      { width: 507, height: 339 },
      { width: 294, height: 441 },
      { width: 399, height: 265 },
      { width: 294, height: 441 },
      { width: 300, height: 450 },
      { width: 388, height: 259 },
      { width: 531, height: 354 },
      { width: 466, height: 311 },
      { width: 529, height: 353 },
    ];

    return (
      <Box ref={ref} paddingBottom={[0, 13]} backgroundColor={backgroundColor}>
        <Wrapper>
          <Container alignItems="flex-end" styleString="z-index: 10; top:0;">
            {[0, 1, 2, 3, 4, 5].map(item => (
              <ImgContainer
                key={item}
                src={imgContainerBg(photos[item])}
                width={imagesInfo[item].width + 'px'}
                height={imagesInfo[item].height + 'px'}
              />
            ))}
          </Container>
          <SecondContainer alignItems="flex-start" styleString="z-index: 1; bottom: 122px;">
            {[6, 7, 8, 9, 10].map(item => (
              <ImgContainer
                key={item}
                src={imgContainerBg(photos[item])}
                width={imagesInfo[item].width + 'px'}
                height={imagesInfo[item].height + 'px'}
              />
            ))}
          </SecondContainer>
        </Wrapper>
      </Box>
    );
  }
);
