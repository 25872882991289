import { themeMediaQuery } from '@utils/styledTheme.util';
import styled from 'styled-components';

export const ImgContainer = styled.div<{ src: string; width: string; height: string; styleString?: string }>`
  height: ${props => props.height};
  width: ${props => props.width};
  background-image: ${props => `url(${props.src})`};
  background-size: cover;
  margin: 9px;
  ${props => props.styleString};
`;

export const Container = styled.div<{ alignItems: string; styleString?: string }>`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  align-items: ${props => props.alignItems};
  position: absolute;
  left: -420px;
  ${props => props.styleString};
`;

export const SecondContainer = styled.div<{ alignItems: string; styleString?: string }>`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  align-items: ${props => props.alignItems};
  position: absolute;
  left: 0px;
  ${props => props.styleString};
  ${themeMediaQuery('below_sm')} {
    left: -580px;
  }
`;

export const Wrapper = styled.div`
  position: relative;
  height: 1046px;
`;
