export const appPageData = {
  seoText: 'Joy the App | Free Wedding Website App',

  seo: {
    title: 'Wedding App | Photo Sharing & More | Joy',
    content:
      'The best wedding app for you and your guests. Keep track of wedding details on the go, collect and share photos, make announcements, and more.',
  },

  header: {
    title: 'Get your guests what they need, on the go.',
    content:
      'Your wedding details don’t need to only exist on a desktop. Provide what your guests will need right on their phone in your app.',
  },

  oneStopShop: {
    eyebrow: 'Wedding Registry',
    title: 'One stop for all your shops.',
    content: 'Make it easy for your guests to access all your registries and get you the perfect gift.',
    learnMore: 'Learn more',
    registry: {
      title: 'Aliah & Duane',
      content: 'Wedding Registry',
    },

    crateAndBarrelAlt: 'crate and barrel',
    redCrossAlt: 'red cross',
    snoweAlt: 'snowe',
    amazonAlt: 'amazon',
    targetAlt: 'target',
    westElmAlt: 'west elm',
    honeyfundAlt: 'honeyfund',
    logoAlt: 'amazon',
    stjudeAlt: 'saint jude',
    bbbAlt: 'bed bath and beyond',
    prezolaAlt: 'prezola',
    weddingShopAlt: 'the wedding shop',
    weddingPresentCompanyAlt: 'the wedding present company',
  },

  weddingSchedule: {
    eyebrow: 'Wedding Schedule',
    title: 'Keep everyone up-to-date on your festivities.',
    content: 'Your guests can see their personalized schedule at a glance from their mobile app.',
    altText: 'wedding schedule image',
  },

  travel: {
    eyebrow: 'Transportation and Travel',
    title: 'Help everyone get where they’ve got to go.',
    content:
      'Make sure your guests won’t have trouble getting to your ceremony. Provide a map or allow them to hail a ride right from their app.',
  },

  photos: {
    icons: [
      {
        text: 'Effortless Upload',
        icon: 'app/guest-photo-collection/icon-photo-upload.svg',
        detail: 'Guests can upload photos and even leave well-wishes in your virtual guestbook.',
      },
      {
        text: 'Live slideshow',
        icon: 'app/guest-photo-collection/icon-slideshow.svg',
        detail: 'Share pictures in real-time during the event for all to see and share.',
      },
      {
        text: `Collect Guests' Photos`,
        icon: 'app/guest-photo-collection/icon-book.svg',
        detail: `Create an album of memories you'll cherish for years to come.`,
      },
    ],
    private: 'Have a private IG',
    remember: 'Remember forever Better',
    eyebrow: 'Guest Photos, Reimagined',
    title: 'Get all the best angles.',
    content: 'Capture everyone’s memories of your big day in one place. Have your guests upload their shots from their phones.',
  },

  iosAndroid: {
    eyebrow: '',
    title: 'The best app for you and your guests.',
    content: 'That’s why your app is available on both Android and iOS.',
    download: 'Download',
    joy: 'Joy for iOS or Android',
    altTextAppStore: 'Download Joy from the App Store',
    altTextGooglePlay: 'Download Joy from the Google Play store',
  },

  cx: {
    title: 'And remember,<br/>we’re always here for you.',
    content: 'Chat with the best customer service team in weddings—<br/>we’re here to make sure you get to the big day without the fray.',
    caption: 'Scott, Team Member since 2015',
    quote: '“I get paid to help people in love — it doesn’t get better than that!”',
  },

  faqTitle: 'Still have questions?',
  appStoreAltText: 'app store',
  googlePlayAltText: 'google play',

  statsSection: {
    title: 'We’ve helped millions celebrate love around the world.',
    subtitle: 'That’s more cake, flowers and happy tears than we can count.',
  },
  guestStats: [
    {
      title: 'million',
      body: 'The largest guest list in Joy',
      endingNumber: 8,
      incrementFrequency: 150,
    },
    {
      title: 'countries',
      body: 'Screaming babies have interrupted weddings on',
      endingNumber: 86,
      incrementFrequency: 100,
    },
    {
      title: 'guests',
      body: 'People around the world',
      endingNumber: 768,
      incrementFrequency: 20,
    },
  ],
  giveTheBestAndHostGoData: {
    giveTheBest: {
      title: 'Give the best to your guests.',
      body: 'Give your guests all the wedding details they need to know, from your first announcement to your last event.',
      altYellowText: 'Iphone schedule Yellow',
      altRedText: 'Iphone schedule Red',

      moreInfo: [
        {
          icon: 'schedulerHumanaLight2',
          title: 'Personalized Schedules',
          body: 'Allow guests to easily access their schedule, so they’ll never wonder where to be and when.',
        },
        {
          icon: 'flightHumanaLight2',
          title: 'Travel Details',
          body: 'Simplify guest travel with personal recommendations, map integrations, and accommodations booking.',
        },
        {
          icon: 'photoGalleryHumanaLight2',
          title: 'Collect Guest Photos',
          body: 'Gather your guests’ photos in an  unlimited shared album.',
        },
        {
          icon: 'taxiHumanaLight2',
          title: 'Hail a Ride',
          body: 'Make it easy for guests to get to your wedding events with ride-sharing app integrations.',
        },
      ],
    },
    hostGo: {
      title: 'Host on the go.',
      body: 'View and update your wedding info on the go.',
      altText: 'Iphone schedule',
      moreInfo: [
        {
          icon: 'attendeesHumanaLight2',
          title: 'View Attendee List',
          body: 'See responses in real time as guests RSVP.',
        },
        {
          icon: 'announcementHumanaLight2',
          title: 'Virtual Guest Book',
          body: 'Collect well-wishes from your guests in one virtual place.',
        },
        {
          icon: 'websiteHumanaLight2',
          title: 'Preview Your Website',
          body: 'View your website the way it will appear on a desktop. ',
        },
        {
          icon: 'photoUploadHumanaLight2',
          title: 'Photo Sharing',
          body: 'Upload photos to share moments with all your guests.',
        },
      ],
    },
  },
  heroSection: {
    altText: 'hero image',
    scheduleAltText: 'Schedule image',
    hostAltText: 'Host image',
    title: 'All your details, wherever you go.',
    body: 'Keep your wedding at your fingertips with a free mobile app to match your wedding website.',
    altTextAppStore: 'app store',
    altTextGooglePlay: 'google play',
  },
  mobileAppSection: {
    eyebrow: 'Add Gifts on the Go',
    title: 'Use the app to add items wherever, whenever.',
    body: 'Easily manage your registry on the go with the mobile app for iPhone and Android.',
  },
} as const;
