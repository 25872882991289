import React, { useRef } from 'react';
import { Columns } from '@components/Columns';
import {
  StyledSection,
  RightSectionTextPairing,
  AppTopWave,
  TheAppShapeImage,
  TheMobileImage,
  TheGoldThreadImage,
  StyledAppBody,
  LeftSectionWrapper,
} from './AppHeroAppSection.styles';
import { appPageData } from '@data-ts/pages/app';

const appPageAppSectionData = appPageData.mobileAppSection;

interface AppHeroAppSectionProps {}

export const AppHeroAppSection: React.FC<AppHeroAppSectionProps> = () => {
  const sectionRef = useRef<HTMLDivElement>(null);
  return (
    <StyledSection as="section" ref={sectionRef} id="App_The_App">
      <AppTopWave width={'100%'} height={74} src="registry-v2/registry-app/registry-app-top-wave.svg" optimization="aggressive" />
      <StyledAppBody paddingTop={[14, 0]} paddingBottom={[10, 0]} backgroundColor="humana1Light">
        <Columns centered>
          <Columns.Column width={6}>
            <LeftSectionWrapper>
              <TheAppShapeImage src="app/registry-app/the-app-shape.svg" optimization="aggressive" />
              <TheGoldThreadImage src="app/registry-app/gold-thread-3.svg" optimization="aggressive" />
              <TheMobileImage src="app/registry-app/registry-mobile-v2.png" optimization="aggressive" />
            </LeftSectionWrapper>
          </Columns.Column>
          <Columns.Column width={6}>
            <RightSectionTextPairing
              eyebrow={appPageAppSectionData.eyebrow}
              eyebrowColor="humana5"
              eyebrowVariant="sectionEyeBrow20"
              eyebrowTagName="h3"
              title={appPageAppSectionData.title}
              titleVariant="hed40"
              titleColor="humana5"
              titleTagName="h3"
              body={appPageAppSectionData.body}
              bodyVariant="dek20"
              bodyColor="neutra6"
              bodyTagName="p"
            />
          </Columns.Column>
        </Columns>
      </StyledAppBody>
    </StyledSection>
  );
};
